import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

export default function SidebarN() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // For dropdown

  // Function to toggle sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Toggle dropdown menu
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="relative min-h-screen">
      {/* Top Navbar */}
      <div className="bg-[#afe9fd] w-full h-[30px] fixed top-0 left-0 z-30 flex items-center justify-between rounded-bl-full px-6"></div>

      {/* Sidebar */}
      <div
        className={`bg-[#afe9fd] text-black ${
          isSidebarOpen ? 'w-60' : 'w-20'
        } h-screen pt-20 fixed left-0 z-20 transition-all rounded-tr-full duration-300 rounded-br-lg`}
      >
        {/* Sidebar Header */}
        <div className="flex justify-between px-3 items-center mb-[70px]">
          {isSidebarOpen ? (
            <div className="logo flex justify-between items-center gap-4">
              <i className="bx bx-plus-medical text-2xl text-[#30c1f1] border-l-8 border-b-4 border-black rounded-full p-1"></i>
              <h1 className="text-2xl text-cyan-500 font-bold">HM <br />Hospital</h1>
            </div>
          ) : (
            <i className="bx bxs-hospital text-2xl"></i>
          )}
        </div>

        {/* Sidebar Items */}
        <ul className="space-y-3">
          <li className="flex items-center hover:bg-cyan-100 rounded-lg gap-2">
            <div className="px-3 py-2">
              <i className="bx bxs-dashboard ml-3 text-cyan-500 text-2xl"></i>
            </div>
            {isSidebarOpen && (
              <RouterLink to="/newdash" className="text-xl font-semibold">
                Dashboard
              </RouterLink>
            )}
          </li>

          {/* Dropdown Item */}
          <li className="flex flex-col">
            <button
              className="flex items-center w-full text-left hover:bg-cyan-100 rounded-lg gap-2 p-2"
              onClick={toggleDropdown}
            >
              <div className="px-3 py-2">
                <i className="bx bx-user  text-cyan-500 text-2xl font-bold"></i>
              </div>
              {isSidebarOpen && (
                <>
                  <span className="text-xl font-semibold">Patients</span>
                  <i
                    className={`ml-auto text-xl font-bold bx ${
                      isDropdownOpen ? 'bx-chevron-up' : 'bx-chevron-down'
                    } transition-transform duration-300`}
                  ></i>
                </>
              )}
            </button>

            {/* Dropdown Menu */}
            {isDropdownOpen && (
              <ul className="ml-10 mt-2  space-y-4 transition-all duration-300">
                <li className="flex items-center gap-2">
                  {/* Rounded Plus Icon */}
                  <button className=' hover:bg-blue-500 px-2 py-1 mb-2 rounded-lg font-bold'>
                    <span className="mr-2">
                      <i className="fas fa-plus"></i>
                    </span>
                    <RouterLink to="/addP" className="text-lg  font-medium">
                      Add Patient
                    </RouterLink>
                  </button>
                </li>
                <li>
                  <RouterLink  className="text-lg ml-4 hover:bg-blue-500 rounded-lg px-2 py-1 font-medium">
                    Patients List
                  </RouterLink>
                </li>
              </ul>
            )}
          </li>

          
          <li className="flex items-center hover:bg-cyan-100 rounded-lg gap-2">
            <div className="px-3 py-2">
              <i className="fa-solid ml-3 fa-user-doctor text-cyan-500 font-bold text-2xl"></i>
            </div>
            {isSidebarOpen && (
              <Link  className="text-xl font-semibold">
                Doctors
              </Link>
            )}
          </li>

          <li className="flex items-center hover:bg-cyan-100 rounded-lg gap-2">
            <div className="px-3 py-2">
              <i className="fa-solid ml-3 fa-calendar-days text-cyan-500 text-2xl"></i>
            </div>
            {isSidebarOpen && (
              <Link  className="text-xl font-semibold">
                Appointments
              </Link>
            )}
          </li>
          <li className="flex items-center hover:bg-cyan-100 rounded-lg gap-2 ">
            <div className="px-3 py-2">
              <i className="fa-regular ml-3 fa-credit-card text-cyan-500 text-2xl"></i>
            </div>
            {isSidebarOpen && (
              <Link  className="text-xl font-semibold">
                Payments
              </Link>
            )}
          </li>
        </ul>
        <hr className="text-gray-200 w-full border mt-10" />
        
        <div className="flex items-center hover:bg-red-400 cursor-pointer rounded-lg gap-2 mt-5">
          <div className="px-3 py-2">
            <i className="bx bx-log-out font-bold text-2xl  text-red-600"></i>
          </div>
          {isSidebarOpen && (
            <h1 className="text-xl font-semibold">
              <RouterLink to="/login">Logout</RouterLink>
            </h1>
          )}
        </div>
      </div>

      {/* Menu icon to show sidebar */}
      {!isSidebarOpen && (
        <button
          onClick={toggleSidebar}
          className="bx bx-menu text-2xl text-white fixed top-5 left-5 z-10 cursor-pointer"
        ></button>
      )}
    </div>
  );
}
