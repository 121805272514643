import React from 'react';
import DNavbar from './DNavbar';
import Sidebar from './Sidebar';
import p1 from '../images/p1jpeg.jpeg'
import adam from '../images/adamjpeg.jpeg'
import alex from '../images/alexjpeg.jpeg'
import david from '../images/davidjpeg.jpeg'
import hugo from '../images/hugojpeg.jpeg'
import mich from '../images/mich.jpeg'
import YearlyIncrementChart from './YearlyIncrementChart'; 
import { Link as RouterLink } from 'react-router-dom';

export default function Patients() {
    const monthlyIncrements = [10, 15, 12, 20, 25, 30, 18, 22, 27, 35, 40, 45]; // Replace with actual monthly data
    const totalPatients = 300; // Replace with the total patient count
    const earnings = [3000, 4000, 5000, 7000, 6000, 8000, 9000, 12000, 11000, 15000, 14000, 16000];

  return (
    <div className="bg-gray-100 shadow-lg min-h-screen">
      <div className="">
        <DNavbar />
        <div className="-mt-[100px] xl:hidden">
          <Sidebar />
        </div>

        <div>
            <input type="text" placeholder='Search Patients' className='rounded-lg px-2 hidden xl:block py-1 md:w-[250px] mt-5 xl:ml-[50px] w-[300px] -ml-[400px]' />
          </div>

        <div className="flex px-[100px] sm:px-[90px] py-[50px] flex-wrap justify-between  items-center">
          {/* Patient */}
          <div className="mt-[70px]   ml-[230px] sm:-ml-[30px] lg:-ml-[10px] lg:px-[30px] lg:-mt-[10px] lg:py-[50px]">
            <div className="">
              <button className="bg-cyan-500 rounded-lg hover:bg-gray-600 px-2 py-1 mb-2 text-white font-bold">
                <span>
                  <RouterLink to="/addP">Add Patients</RouterLink>
                </span>
                <i className="fa-solid fa-circle-plus ml-1"></i>
              </button>
            </div>
            <div className="w-[300px] xm:w-auto  py-[10px] bg-white flex px-[15px] justify-between items-center cursor-pointer hover:scale-105 duration-300 max-h-screen rounded-lg  ">
              <div className='flex flex-col gap-5'>
                <h1 className='font-bold'>Total Patients</h1>
                <p className='font-bold'>10</p>
                <p>Total Patients <span className='text-cyan-400'>10</span> today</p>
              </div>
              <div>
              <i className="fa-regular bg-cyan-400 fa-user text-xl px-2 py-1 rounded-lg text-white font-bold"></i>
              </div>
            </div>
          </div>

          {/* monthly */}
          <div className="w-[300px] xm:w-auto xm:mt-1 mt-[100px] py-[10px] bg-white flex px-[15px] justify-between items-center cursor-pointer hover:scale-105 duration-300 max-h-screen rounded-lg  ">
              <div className='flex flex-col gap-5'>
                <h1 className='font-bold'>Monthly Patients</h1>
                <p className='font-bold'>230</p>
                <p>Total Patients <span className='text-orange-400'>230</span> this month</p>
              </div>
              <div>
              <i className="fa-solid bg-orange-400 fa-calendar-days text-xl px-2 py-1 rounded-lg text-white font-bold"></i>
              </div>
            </div>
          

          {/* yearly */}
          <div className="w-[300px] xm:w-auto xm:mt-10  mt-[100px] py-[15px] bg-white flex px-[20px] justify-between items-center cursor-pointer hover:scale-105 duration-300 max-h-screen rounded-lg  ">
              <div className='flex flex-col gap-5'>
                <h1 className='font-bold'>Yearly Patients</h1>
                <p className='font-bold'>1,500</p>
                <p>Total Patients <span className='text-green-400'>1500</span> this year</p>
              </div>
              <div>
              <i className="fa-solid bg-green-400 fa-users text-xl px-2 py-1 rounded-lg text-white font-bold"></i>
              </div>
            </div>
        </div>

        <div className='lg:overflow-x-auto ml-[150px] xl:ml-0 -mt-[50px] py-[50px]'>
            <div className='bg-white rounded-lg m-auto  mt-5 shadow-lg  w-[1000px] p-5 '>
                <div>
                    <input type="text" className='px-2 bg-gray-100 rounded border border-blue-300' placeholder='Search Patients' />
                </div>
                <table className=' mt-3  rounded-lg  w-full '>
                <thead className='bg-gray-100 rounded-lg'>
                  <tr>
                  <th className='p-3 text-sm font-bold tracking-wide'>#</th>
                    <th className='p-3 text-sm font-bold tracking-wide'>Patient</th>
                    <th className='p-3 text-sm font-bold tracking-wide'>Created At</th>
                    <th className='p-3 text-sm font-bold tracking-wide'>Gender</th>
                    <th className='p-3 text-sm font-bold tracking-wide'>Blood Group</th>
                    <th className='p-3 text-sm font-bold tracking-wide'>Age</th>
                    <th className='p-3 text-sm font-bold tracking-wide'>Action</th>
                  </tr>
                </thead>
                <tbody>
                    <tr>
                       <td className='p-3 text-center font-bold'>
                        1
                      </td>
                      <td className='p-3  '>
                        <div className='flex justify-center gap-5 items-center'>
                          <img src={hugo} className='w-[45px] h-[45px] rounded-full' alt="" />
                          <div className=''>
                            <h1 className='font-bold'>Hugo Loris</h1>
                            <p className='text-gray-400'>1234567890</p>
                          </div>
                        
                        </div>
                      </td>
                      <td className='p-3 text-center font-semibold'>
                        Oct 30,2022
                      </td>
                      <td className='p-3 text-center '>
                      <p className='text-green-500 rounded-full px-1 font-semibold bg-gray-100'>Male</p>
                      </td>
                      <td className='p-3 text-center font-semibold'>
                        A+
                      </td>
                      <td className='p-3 text-center font-semibold'>
                        25
                      </td>
                      <td className='p-3 text-center font-semibold'>
                      <i className="fa-solid text-xl text-red-500 cursor-pointer hover:scale-105 duration-300 fa-trash"></i>
                      </td>
                    </tr>
    
                    <tr>
                       <td className='p-3 text-center font-bold'>
                        2
                      </td>
                      <td className='p-3  '>
                        <div className='flex justify-center gap-5 items-center'>
                          <img src={adam} className='w-[45px] h-[45px] rounded-full' alt="" />
                          <div className=''>
                            <h1 className='font-bold'>Mouris joe</h1>
                            <p className='text-gray-400'>1234567890</p>
                          </div>
                        
                        </div>
                      </td>
                      <td className='p-3 text-center font-semibold'>
                        Oct 29,2023
                      </td>
                      <td className='p-3 text-center '>
                      <p className='text-green-500 rounded-full px-1 font-semibold bg-gray-100'>Male</p>
                      </td>
                      <td className='p-3 text-center font-semibold'>
                        B+
                      </td>
                      <td className='p-3 text-center font-semibold'>
                        26
                      </td>
                      <td className='p-3 text-center font-semibold'>
                      <i className="fa-solid text-xl text-red-500 cursor-pointer hover:scale-105 duration-300 fa-trash"></i>
                      </td>
                    </tr>
    
                    <tr>
                       <td className='p-3 text-center font-bold'>
                        3
                      </td>
                      <td className='p-3  '>
                        <div className='flex justify-center gap-5 items-center'>
                          <img src={mich} className='w-[45px] h-[45px] rounded-full' alt="" />
                          <div className=''>
                            <h1 className='font-bold'>Michel oven</h1>
                            <p className='text-gray-400'>1234567890</p>
                          </div>
                        
                        </div>
                      </td>
                      <td className='p-3 text-center font-semibold'>
                        Jan 29,2024
                      </td>
                      <td className='p-3 text-center '>
                      <p className='text-green-500 rounded-full px-1 font-semibold bg-gray-100'>Male</p>
                      </td>
                      <td className='p-3 text-center font-semibold'>
                        O+
                      </td>
                      <td className='p-3 text-center font-semibold'>
                        22
                      </td>
                      <td className='p-3 text-center font-semibold'>
                      <i className="fa-solid text-xl text-red-500 cursor-pointer hover:scale-105 duration-300 fa-trash"></i>
                      </td>
                    </tr>
    
                    <tr>
                       <td className='p-3 text-center font-bold'>
                        4
                      </td>
                      <td className='p-3  '>
                        <div className='flex justify-center gap-5 items-center'>
                          <img src={p1} className='w-[45px] h-[45px] rounded-full' alt="" />
                          <div className=''>
                            <h1 className='font-bold'>maya</h1>
                            <p className='text-gray-400'>1234567890</p>
                          </div>
                        
                        </div>
                      </td>
                      <td className='p-3 text-center font-semibold'>
                        Jan 39,2024
                      </td>
                      <td className='p-3 text-center '>
                      <p className='text-orange-500 rounded-full px-1 font-semibold bg-gray-100'>Female</p>
                      </td>
                      <td className='p-3 text-center font-semibold'>
                        A+
                      </td>
                      <td className='p-3 text-center font-semibold'>
                        24
                      </td>
                      <td className='p-3 text-center font-semibold'>
                      <i className="fa-solid text-xl text-red-500 cursor-pointer hover:scale-105 duration-300 fa-trash"></i>
                      </td>
                    </tr>
    
                </tbody>
              </table>
    
                </div>
        </div>

        
      </div>

      
    </div>
  );
}
