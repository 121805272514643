import React, { useState } from 'react';
import image from '../images/admin.png';
import { Link as RouterLink } from 'react-router-dom'; 
import { Link } from 'react-scroll';


export default function DNavbar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isUserSidebar, setIsuserSidebar] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); 

  
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };


  // Function to toggle sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleUserSidebar = () => {
    setIsuserSidebar(!isUserSidebar);
  };

  return (
    <div className="relative">
      {/* Navbar */}
      <nav className="bg-[#f4fcff] h-[80px] sm:px-[10px]  px-[100px] py-[20px]">
        <div className="flex justify-between items-center">
          {/* Menu Icon */}
          <i
            onClick={toggleSidebar}
            className="bx bx-menu text-2xl text-cyan-500 font-bold cursor-pointer"
          ></i>

          <div>
          <input type="text" placeholder='Search "Patients"' className='rounded-lg hidden px-2 border border-cyan-400 bg-gray-100 xl:hidden   py-1 md:w-[250px]   w-[300px] ' />
          </div>

          

          {/* User Info */}
          <div className="flex items-center gap-6">
          <div className='flex items-center cursor-pointer  gap-5'>
            <div className='relative'>
              <div className='border border-gray-200  hover:bg-gray-100 rounded-full px-3 py-2'>
                <i className="fa-regular fa-bell text-xl font-bold text-green-400"></i>
              </div>
              <span className='absolute -top-2 -right-1 bg-red-500 text-white rounded-full text-xs font-bold px-2 py-1'>2</span>
            </div>
            <div className='relative'>
              <div className='border border-gray-200 hover:bg-gray-100 rounded-full px-3 py-2'>
                <i className="fa-solid fa-gift text-xl font-bold text-green-400"></i>
              </div>
              <span className='absolute -top-2 -right-1 bg-blue-500 text-white rounded-full text-xs font-bold px-2 py-1'>5</span>
            </div>
          </div>
           <div className='flex rounded-full bg-gray-100 items-center '>
              <img
                src={image}
                onClick={toggleUserSidebar}
                alt="Admin"
                className="w-[40px] h-[40px] rounded-full cursor-pointer bg-gray-500"
              />
              <h1 className=" font-bold text-green-400 px-2">Hello, Admin</h1>
           </div>
          </div>
          
        </div>
      </nav>


      <div className={`fixed top-0 right-0 w-[200px] rounded-lg shadow-lg bg-gray-50 p-4 transform transition-transform duration-300 ${isUserSidebar ? "translate-x-0" : "translate-x-[400px]"}`}>
          <span className="float-right font-bold text-red-600 hover:text-red-400 self-end text-2xl cursor-pointer" onClick={toggleUserSidebar}>
            X
          </span>
          <div className="flex flex-col text-center gap-4 mt-10">
            
               <button className='bg-red-600 rounded-lg hover:bg-red-500 px-3 py-1 '>
                  <span className="  font-semibold">
                <RouterLink to='/'>Logout</RouterLink>
              </span>
               </button>
          </div>
        </div>

      {/* Sidebar */}
      <div
        className={`bg-[#afe9fd]  w-64 h-screen p-5 fixed top-0 left-0 z-20 transition-transform duration-300 ease-in-out ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="flex justify-between items-center mb-8">
        <div className='logo flex justify-between items-center gap-4'>
            <i className='bx bx-plus-medical text-2xl text-cyan-500 border-l-8 border-b-4 border-black rounded-full p-1'></i>
            <h1 className='text-2xl  text-cyan-500 font-bold'>HM Hospital</h1>
        </div>
          <i
            onClick={toggleSidebar}
            className="bx bx-x text-xl font-bold cursor-pointer"
          ></i>
        </div>
        <ul className="space-y-5">
          <li className="flex items-center hover:bg-cyan-100 rounded-lg gap-2">
            <div className="px-3 py-2">
              <i className="bx bxs-dashboard text-cyan-500 text-2xl"></i>
            </div>
            {isSidebarOpen && (
              <Link  className=" text-xl font-semibold">
                <RouterLink to='/newdash'>Dashboard</RouterLink>
              </Link>
            )}
          </li>
          <li className="flex flex-col">
            <button
              className="flex items-center w-full text-left hover:bg-cyan-100 rounded-lg gap-2 p-2"
              onClick={toggleDropdown}
            >
              <div className="px-3 py-2">
                <i className="bx bx-user  text-cyan-500 text-2xl font-bold"></i>
              </div>
              {isSidebarOpen && (
                <>
                  <span className="text-xl font-semibold">Patients</span>
                  <i
                    className={`ml-auto text-xl font-bold bx ${
                      isDropdownOpen ? 'bx-chevron-up' : 'bx-chevron-down'
                    } transition-transform duration-300`}
                  ></i>
                </>
              )}
            </button>

            {/* Dropdown Menu */}
            {isDropdownOpen && (
              <ul className="ml-10 mt-2  space-y-4 transition-all duration-300">
                <li className="flex items-center gap-2">
                  {/* Rounded Plus Icon */}
                  <button className=' hover:bg-blue-500 px-2 py-1 mb-2 rounded-lg font-bold'>
                    <span className="mr-2">
                      <i className="fas fa-plus"></i>
                    </span>
                    <RouterLink to="/addP" className="text-lg  font-medium">
                      Add Patient
                    </RouterLink>
                  </button>
                </li>
                <li>
                  <RouterLink  className="text-lg ml-4 hover:bg-blue-500 rounded-lg px-2 py-1 font-medium">
                    Patients List
                  </RouterLink>
                </li>
              </ul>
            )}
          </li>

          <li className="flex items-center hover:bg-cyan-100 rounded-lg gap-2">
            <div className="px-3 py-2">
              <i className="fa-solid fa-user-doctor text-cyan-500 font-bold text-2xl"></i>
            </div>
            {isSidebarOpen && (
              <Link to="/doctors" className=" text-xl font-semibold">
                Doctors
              </Link>
            )}
          </li>

          <li className="flex items-center hover:bg-cyan-100 rounded-lg gap-2">
            <div className="px-3 py-2">
              <i className="fa-solid fa-calendar-days text-cyan-500 text-2xl"></i>
            </div>
            {isSidebarOpen && (
              <Link to="/appointments" className=" text-xl font-semibold">
                Appointments
              </Link>
            )}
          </li>
          <li className="flex items-center hover:bg-cyan-100 rounded-lg gap-2">
            <div className="px-3 py-2">
              <i className="fa-regular fa-credit-card text-cyan-500 text-2xl"></i>
            </div>
            {isSidebarOpen && (
              <Link to="/payments" className="text-xl font-semibold">
                Payments
              </Link>
            )}
          </li>
        </ul>
        <hr className="text-gray-200 w-full border mt-10" />
        <div className="flex items-center hover:bg-red-400 cursor-pointer rounded-lg gap-2 mt-5">
          <div className="px-3 py-2">
            <i className="bx bx-log-out font-bold text-2xl  text-red-600"></i>
          </div>
          {isSidebarOpen && (
            <h1 className="text-xl font-semibold">
              <RouterLink to="/login">Logout</RouterLink>
            </h1>
          )}
        </div>
      </div>
     
    </div>
  );
}
