import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/Home/Home';
import Login from './components/Auth/Login';
import Signup from './components/Auth/Signup';
import AddPatient from './components/Dashboard/AddPatient';
import Patients from './components/Dashboard/Patients';
import NewDash from './components/Dashboard/NewDash';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/login' element={<Login/>} />
          <Route path='/signup' element={<Signup/>} />
          
          <Route path='/newdash' element={<NewDash/>}/>
          <Route path='/addP' element={<AddPatient/>} />
          <Route path='/patients' element={<Patients/>} />
        </Routes>
      </Router>
      
    </div>
  );
}

export default App;
