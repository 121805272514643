import React from 'react'

import Footer from './Footer'
import HomeNav from './HomeNav'
import d1 from '../images/Doctor_1-removebg-preview.png'
import d3 from '../images/doc5.png'
import d4 from '../images/doc4.png'
import d13 from '../images/doc13.png'
import d15 from '../images/doc15.png'
import d10 from '../images/doc10.png'
import d14 from '../images/doc14.png'
import d12 from '../images/doc12.png'
import d11 from '../images/doc11.png'
import d7 from '../images/doc7.png'
import banner from '../images/appointment_img.png'
import st from '../images/stethjpeg.jpeg'
import ab from '../images/abtjpeg.jpeg'




import { Link as RouterLink } from 'react-router-dom'; 
export default function Home() {
  return (
    <div className='bg-[#f9fff4]  '>
      <div className='section-1 md:bg-center'><HomeNav/></div>

    

    {/* <div  className=' section-1  py-[150px] 2xl:px-[150px] sm:p-36 xxm:py-[100px]    md:py-[150px] md:px-[100px] sm:px-[30px] px-[250px] '>
      
        <div className=''>
          <div className=''>
               <div className='headi max-h-screen xxxm:-mt-[40px] '>
                    <h1 className='commit text-[30px] font-semibold text-blue-600 mb-4 sm:text-[20px] xm:text-[15px] '> COMMITTED TO SUCCESS</h1>
                    <h1 className='big text-[#102039] font-bold text-[70px] w-[60%] xm:text-[20x]'>WE CARE ABOUT YOUR <span className='text-[#1F2B7B]'>HEALTH</span></h1>
                    <p className='w-[50%] text-[#212025] mt-4 text-[15px]'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum, omnis accusantium maxime culpa aliquid </p>
                    <button className='bg-blue-700 hover:bg-blue-400  px-10 py-2 font-bold text-white rounded-lg mt-4'><RouterLink to='/login'>Login</RouterLink></button>
               </div>
          </div>
        </div>
    </div> */}
    

    <div className='py-[50px] xm:-mt-[60px] '>
      <div className='m-auto xxm:w-auto  w-[500px] xm:w-[300px] '>
        <h1 className='font-bold text-center xxm:text-xl text-[#33ae99] md:text-2xl text-3xl mt-10' id='services'>Our Healthcare Service</h1>
        <p className='mt-2 w-[30% ] text-[15px]   text-[#1F2B7B] text-center'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo saepe enim veniam repellendus soluta ab ducimus cupiditate labore </p>
        </div>

        <div className='flex mt-10 xxxm:overflow-x-auto lg:justify-center px-[180px] 2xl:px-[100px] xl:px-[60px] sm:px-[20px]   gap-12 items-center flex-wrap'>
          <div className='w-[350px]   hover:scale-105 duration-300 cursor-pointer rounded-lg shadow-lg bg-[#35ae99] p-5 max-h-screen'>
            <div className='flex justify-between gap-3 items-center'>
              <div className='bg-white rounded-full p-4 text-2xl text-[#35ae99]'><i class="fa-solid fa-truck-medical"></i></div>
              <div>
                <h1 className='text-white  text-xl font-bold'>Emergency Department</h1>
                <p className='text-white text-[15px]'>Lorem ipsum, dolor sit amet consectetur </p>
                </div>
            </div>
            </div>

            <div className='w-[350px] hover:scale-105 duration-300 cursor-pointer rounded-lg shadow-lg bg-[#35ae99] p-5 max-h-screen'>
            <div className='flex justify-between gap-4 items-center'>
              <div className='bg-white rounded-full p-4 text-2xl text-[#35ae99]'><i class="fa-solid fa-baby "></i></div>
              <div>
                <h1 className='text-white  text-xl font-bold'>Pediatric Department</h1>
                <p className='text-white  text-[15px]'>Lorem ipsum, dolor sit amet consectetur </p>
                </div>
            </div>
            </div>

            <div className='w-[350px] hover:scale-105 duration-300 cursor-pointer rounded-lg shadow-lg bg-[#35ae99] p-5 max-h-screen'>
            <div className='flex justify-between gap-3 items-center'>
              <div className='bg-white rounded-full p-4 text-2xl text-[#35ae99]'><i class="fa-solid fa-stethoscope"></i></div>
              <div>
                <h1 className='text-white  text-xl font-bold'>General Physician</h1>
                <p className='text-white text-[15px]'>Lorem ipsum, dolor sit amet consectetur </p>
                </div>
            </div>
            </div>

            
              
                <div className='w-[350px] ml-[150px] 2xl:ml-0 hover:scale-105 duration-300 cursor-pointer rounded-lg shadow-lg bg-[#35ae99] p-5 max-h-screen'>
                <div className='flex justify-between gap-3 items-center'>
                <div className='bg-white rounded-full p-4  text-[#35ae99]'><i class="fa-solid text-2xl fa-brain"></i></div>
                  <div>
                    <h1 className='text-white  text-xl font-bold'>Neurology Department</h1>
                    <p className='text-white text-[15px]'>Lorem ipsum, dolor sit amet consectetur </p>
                    </div>
                </div>
                </div>
                <div className='w-[350px] hover:scale-105 duration-300 cursor-pointer rounded-lg shadow-lg bg-[#35ae99] p-5 max-h-screen'>
                <div className='flex justify-between gap-3 items-center'>
                <div className='bg-white rounded-full p-4 text-2xl text-[#35ae99]'><i class="fa-solid fa-heart "></i></div>
                <div>
                    <h1 className='text-white  text-xl font-bold'>Cardiology Department</h1>
                    <p className='text-white text-[15px]'>Lorem ipsum, dolor sit amet consectetur </p>
                    </div>
                </div>
                </div>
             
           

        </div>

        

    </div>

    <div className='py-[50px]  md:-mt-[80px]'>
        <div id='doctor' className='m-auto xxm:w-auto w-[500px] md:w-[300px] '>
        <h1 className='font-bold text-center  text-[#33ae99] md:text-2xl text-3xl mt-10' id='services'>Top Doctors to Book</h1>
        <p  className='mt-2 w-[30% ] text-[15px] xxm:text-[10px]   text-[#1F2B7B] text-center'>Simply browse our extensive list of trusted doctors</p>
        </div>

        <div className='flex justify-between   lg:px-[60px] xl:px-[70px] 2xl:px-[80px]  xxm:ml-0  xxm:px-[20px]  mt-10 gap-10 flex-wrap px-[170px] items-center'>

            <div className='w-[200px] max-h-screen xm:m-auto  border-[#afe9fd] hover:scale-105 cursor-pointer duration-300 rounded-lg border '>
              <div className='bg-gray-300 border rounded'>
                <img src={d1} className=' m-auto h-[200px] w-[200px]' alt="" />
              </div>
              <div className='px-[10px] py-[10px] flex flex-col'>
                <p className='text-green-500'>Available</p>
                <h1 className='text-xl font-bold'>Dr. Richard James</h1>
                <p>General Physician</p>
              </div>
            </div>

            <div className='w-[200px] xm:m-auto max-h-screen border-[#afe9fd] hover:scale-105 cursor-pointer duration-300 rounded-lg border '>
              <div className='bg-gray-300 border rounded '>
                <img src={d3} className=' m-auto h-[200px] w-[200px]' alt="" />
              </div>
              <div className='px-[10px] py-[10px] flex flex-col'>
                <p className='text-green-500'>Available</p>
                <h1 className='text-xl font-bold'>Dr. Emily larson</h1>
                <p>Gynecologist</p>
              </div>
            </div>

            <div className='w-[200px] xm:m-auto max-h-screen border-[#afe9fd] hover:scale-105 cursor-pointer duration-300 rounded-lg border '>
              <div className='bg-gray-300 border rounded '>
                <img src={d4} className=' m-auto h-[200px] w-[200px]' alt="" />
              </div>
              <div className='px-[10px] py-[10px] flex flex-col'>
                <p className='text-green-500'>Available</p>
                <h1 className='text-xl font-bold'>Dr. Christofer lee</h1>
                <p>Pediatricians</p>
              </div>
            </div>

            <div className='w-[200px] xm:m-auto max-h-screen border-[#afe9fd] hover:scale-105 cursor-pointer duration-300 rounded-lg border '>
              <div className='bg-gray-300 border rounded '>
                <img src={d13} className=' m-auto h-[200px] w-[200px]' alt="" />
              </div>
              <div className='px-[10px] py-[10px] flex flex-col'>
                <p className='text-green-500'>Available</p>
                <h1 className='text-xl font-bold'>Dr. Jennifer Gracia</h1>
                <p>Neurologist</p>
              </div>
            </div>


            <div className='w-[200px] xm:m-auto max-h-screen border-[#afe9fd] hover:scale-105 cursor-pointer duration-300 rounded-lg border '>
              <div className='bg-gray-300 border rounded '>
                <img src={d15} className=' m-auto h-[200px] w-[200px]' alt="" />
              </div>
              <div className='px-[10px] py-[10px] flex flex-col'>
                <p className='text-green-500'>Available</p>
                <h1 className='text-xl font-bold'>Dr. sarah patel</h1>
                <p>Dermatologist</p>
              </div>
            </div>


            <div className='w-[200px] xm:m-auto max-h-screen border-[#afe9fd] hover:scale-105 cursor-pointer duration-300 rounded-lg border '>
              <div className='bg-gray-300 border rounded '>
                <img src={d10} className=' m-auto h-[200px] w-[200px]' alt="" />
              </div>
              <div className='px-[10px] py-[10px] flex flex-col'>
                <p className='text-green-500'>Available</p>
                <h1 className='text-xl font-bold'>Dr. Andrew Tate</h1>
                <p>Pediatricians</p>
              </div>
            </div>


            <div className='w-[200px] xm:m-auto max-h-screen border-[#afe9fd] hover:scale-105 cursor-pointer duration-300 rounded-lg border '>
              <div className='bg-gray-300 border rounded '>
                <img src={d14} className=' m-auto h-[200px] w-[200px]' alt="" />
              </div>
              <div className='px-[10px] py-[10px] flex flex-col'>
                <p className='text-green-500'>Available</p>
                <h1 className='text-xl font-bold'>Dr. Louis Joe</h1>
                <p>General Phycisian</p>
              </div>
            </div>


            <div className='w-[200px] xm:m-auto max-h-screen border-[#afe9fd] hover:scale-105 cursor-pointer duration-300 rounded-lg border '>
              <div className='bg-gray-300 border rounded '>
                <img src={d12} className=' m-auto h-[200px] w-[200px]' alt="" />
              </div>
              <div className='px-[10px] py-[10px] flex flex-col'>
                <p className='text-green-500'>Available</p>
                <h1 className='text-xl font-bold'>Dr. Tim white</h1>
                <p>Gynecologist</p>
              </div>
            </div>

            <div className='w-[200px] xm:m-auto max-h-screen border-[#afe9fd] hover:scale-105 cursor-pointer duration-300 rounded-lg border '>
              <div className='bg-gray-300 border rounded '>
                <img src={d11} className=' m-auto h-[200px] w-[200px]' alt="" />
              </div>
              <div className='px-[10px] py-[10px] flex flex-col'>
                <p className='text-green-500'>Available</p>
                <h1 className='text-xl font-bold'>Dr. Ava Mitchell</h1>
                <p>Neurologist</p>
              </div>
            </div>


            <div className='w-[200px] xm:m-auto max-h-screen border-[#afe9fd] hover:scale-105 cursor-pointer duration-300 rounded-lg border '>
              <div className='bg-gray-300 border rounded '>
                <img src={d7} className=' m-auto h-[200px] w-[200px]' alt="" />
              </div>
              <div className='px-[10px] py-[10px] flex flex-col'>
                <p className='text-green-500'>Available</p>
                <h1 className='text-xl font-bold'>Dr. Jeffrey king</h1>
                <p>Pediatricians</p>
              </div>
            </div>



        </div>
    </div>

    {/* <div className='py-[50px]  sm:px-1 2xl:px-[100px] px-[180px] md:-mt-[50px]'>
      <div className='bg-blue-600 flex   flex-wrap 2xl:w-auto justify-between items-center  w-[950px] rounded-lg m-auto'>
      <div className='px-[50px] sm:px-1'>
        <h1 className='text-white xm:text-[20px] text-[35px]  font-bold'>Book Appointment <br /> With 100+ Trusted Doctors</h1>
        <button type='submit' className='bg-white mt-5 hover:bg-gray-100  py-1 px-4 rounded-full '><RouterLink to='/signup'>Create Account</RouterLink></button>
      </div>
      <div className='px-[20px]  2xl:ml-[200px] sm:px-0'>
        <img src={banner} className='h-[300px] w-[300px] lg:w-[200px] lg:h-[200px] xm:w-[150px] xm:h-[150px] -mt-[30px] ' alt="" />
      </div>

      </div>

    </div> */}

    <div className='py-[50px] lg:m-auto xl:px-[100px] lg:px-[20px] px-[180px]'>
        <div id='about' className=' '>
          <h2 className='text-3xl font-bold text-[#33ae99] text-center mb-10'>About Us</h2>
          <div className=''>
            <div className='flex mb-10 xl:flex-wrap xl:-mt-10 justify-between gap-10 '>
              <img src={st} className='w-[200px] lg:mt-5 xl:mt-[50px] lg:m-auto rounded-lg mt-5 h-[200px]' alt="" />
              <div>
            
              <h1 className='text-2xl mt-3 lg:text-center font-semibold mb-2'>Caring for You, Every Step of the Way</h1>
                <p className='text-gray-700 text-lg'>
                At our healthcare facility, we believe in more than just treatment—we believe in building lasting relationships with our patients. Our dedicated team is here to provide expert medical care tailored to each individual, ensuring that every experience is met with compassion and excellence.
                Our state-of-the-art technology, along with a team of experienced professionals, allows us to address a wide range of healthcare needs effectively and efficiently.
                We aim to empower you with knowledge and provide you with the tools for a healthier, happier life.
              </p>
              <p className='text-gray-700 text-lg'>
                From preventive care to complex medical treatments, we’re committed to supporting you and your family’s health journey at every stage. Thank you for making us your trusted healthcare partner.
                Together, let's continue to promote wellness and enhance the quality of life for our entire community.
              </p>
            
          </div>
            </div>

            <div className='flex xl:flex-wrap-reverse justify-between gap-10 '>
              <div>
            <h1 className='text-2xl mt-3 lg:mt-1 lg:text-center  font-semibold mb-2'>Your Health, Our Priority</h1>
              <p className='text-gray-700 text-lg'>
              Welcome to our healthcare facility, where we are committed to providing high-quality and compassionate care to our community. With a team of skilled professionals, state-of-the-art facilities, and a patient-centered approach, we strive to make each visit as comfortable and effective as possible.
              Our mission is to improve the health and well-being of those we serve through innovation, dedication, and a personal touch.
              Whether it's routine check-ups or specialized treatments, we're here for you every step of the way.
              Thank you for trusting us with your healthcare needs. Together, let's build a healthier future.
            </p>
            <p className='text-gray-700 text-lg'>
              From preventive care to complex medical treatments, we’re committed to supporting you and your family’s health journey at every stage. Thank you for making us your trusted healthcare partner.
              Together, let's continue to promote wellness and enhance the quality of life for our entire community.
            </p>
          </div>
          <img src={ab} className='w-[200px]  xl:mt-[50px] lg:mt-5 lg:m-auto rounded-lg mt-5 h-[200px]' alt="" />
            </div>
          
          </div>
        </div>
      </div>

      {/* Contact Us Section */}
      {/* <div id='contact' className='py-[30px] xl:px-[20px] px-[180px] bg-gray-100'>
        <div id='contact' className='m-auto w-full  md:w-3/4 lg:w-2/3 xl:w-1/2'>
          <h2 className='text-3xl font-bold text-[#1F2B7B] text-center mb-10'>Contact Us</h2>
          <p className='text-center text-lg text-gray-600 mb-6'>
            We'd love to hear from you! Please fill out the form below, and we’ll get back to you as soon as possible.
          </p>

          <form className='space-y-4'>
            <div className='flex flex-wrap justify-between items-center'>
              <div className='w-[500px] '>
                <label className='block text-gray-700 font-semibold mb-2'>Name</label>
                <input
                  type='text'
                  className='w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500'
                  placeholder=' Name'
                />
              </div>
  
              <div className='w-[500px]'>
                <label className='block text-gray-700 font-semibold mb-2'>Email</label>
                <input
                  type='email'
                  className='w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500'
                  placeholder=' email'
                />
              </div>
            </div>

            <div>
              <label className='block text-gray-700 font-semibold mb-2'>Contact Number</label>
              <input
                type='tel'
                className='w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500'
                placeholder=' contact number'
              />
            </div>

            <div>
              <label className='block text-gray-700 font-semibold mb-2'>Message</label>
              <textarea
                type='text'
                className='w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500'
                placeholder='Message'
              />
            </div>

            <div className='text-center'>
              <button
                type='submit'
                className='bg-blue-600 text-white py-2 px-6 rounded-lg font-semibold hover:bg-blue-700 transition duration-300'
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div> */}


    <Footer/>
    </div>
  )
}
