import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom'; 

export default function SignupNav() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    // Function to toggle sidebar
    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div>
            <div className='nav-bar'>
                <nav id='home' className={`navbar  text-white  w-full flex justify-between items-center   2xl:px-[20px] px-[100px] transition-all duration-500 ${isSidebarOpen ? 'h-[230px]' : 'h-[100px]'}`}>
                    <div className='logo flex justify-between items-center gap-4'>
                        <i className='bx bx-plus-medical text-4xl text-[#266c94] border-l-8 border-b-4 border-black rounded-full p-2'></i>
                        <h1 className='text-2xl text-gray-50 XM:hidden  font-bold'>HM Hospital</h1>
                    </div>

                    {/* Desktop Navigation */}
                    <div className="icons flex gap-[50px] font-semibold 2xl:ml-[130px]  ml-[200px] md:flex">
                        <span id="close" className="hidden cursor-pointer mt-[10px] text-red-600">X</span>
                        

                        

                    </div>

                    <div className='flex gap-3'>
                        <button className='regstr bg-cyan-600 hover:bg-blue-500 text-white 2xl:px-2 2xl:py-1 font-bold px-8 rounded-lg py-3'>
                            <RouterLink to='/'>Home</RouterLink> {/* Updated link */}
                        </button>
                        <button className='regstr bg-[#269481] hover:bg-[#35ae99] text-white 2xl:px-2 2xl:py-1 font-bold px-8 rounded-lg py-3'>
                            <RouterLink to='/NewDash'>Admin</RouterLink> {/* Updated link */}
                        </button>
                    </div>

                    {/* Menu icon for mobile */}
                    <div className="menu hidden" onClick={toggleSidebar}>
                        <i className="bx bx-menu  text-white text-4xl font-semibold cursor-pointer"></i>
                    </div>
                </nav>

                <div  className='   2xl:px-[100px]   xm:px-[20px]  md:px-[80px] sm:px-[10px] px-[190px] '>
                <div className=''>
                    
                <section className=' min-h-screen   flex py-[50px] items-center  justify-center'>
                <div className=' xm:p-2   xm:overflow-hidden   border  -mt-[30px] border-white flex rounded-2xl shadow-lg  p-5'>

          <div className='xm:overflow-hidden xm:px-3 px-16'>
            <h2 className='font-bold text-2xl text-[#002D74]'>Registration Form</h2>
            <p className='  mt-4 text-[#002D74]'>New Member, easily Register</p>
            <form className='flex  flex-col'>
              
                  <input
                    className='p-2  mt-6 rounded-xl'
                    placeholder='Enter your First Name'
                    type="text"
                  />
              

              <input
                className='p-2 mt-6 rounded-xl'
               
                placeholder='Enter your Last Name'
                type="text"
               
              />

              <input
                className='p-2 mt-6 rounded-xl'
               
                placeholder='Enter your Email'
                type="text"
              />

              <input
                className='p-2 mt-6 rounded-xl'
                
                placeholder='Enter your mobile Number'
                type="text"
              />

                <select name="" className='rounded-xl text-gray-400 mt-6 p-2' id="">
                    <option value="">Select Gender</option>
                    <option value="" id="">Male</option>
                    <option value="" id="">Female</option>
                    <option value="" id="">Other</option>
                </select>
              


              <div className='flex items-center gap-4'>
                <label className=' font-bold text-[#002D74]' htmlFor="image">Image:</label>
                <input
                  className='p-2 mt-4 rounded-xl'
                  type="file"
                  id='image'
                />
              </div>

              <button
                className='bg-[#002D74] rounded-xl text-white py-2 mt-3 hover:scale-105 duration-300 hover:bg-[#3f649f] font-bold'
              >
                Register
              </button>
            </form>

            <div>
              <hr className='mt-5 mb-3 border-1 border-[#002D74]' />
              <p>Already have an Account? <span className='text-[#002D74] font-bold hover:underline' ><RouterLink to='/login'>Login</RouterLink></span></p>
            </div>

          </div>

        </div>
        
      </section>
                
                </div>
                </div>


            

                <div className={`fixed top-0 right-0 w-full max-h-screen bg-[#35ae99] shadow-lg transform transition-transform ${isSidebarOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                    <div className="flex flex-col p-4">
                        <span className="self-end text-xl cursor-pointer" onClick={toggleSidebar}>X</span>
                        <div className='flex flex-col gap-2'>
                            <span className="hover:text-blue-500 text-[#102039]"><RouterLink to='/'>HOME</RouterLink></span>
                            <span className="hover:text-blue-500  text-[#102039]"><RouterLink to='/newdash'>ADMIN</RouterLink></span>
                            <Link to="doctor" smooth={true} duration={500} className="cursor-pointer text-[#102039] hover:text-blue-500">DOCTORS</Link>
                            <Link to="about" smooth={true} duration={500} className="cursor-pointer hover:text-blue-500 text-[#102039]">ABOUT US</Link>
                            <div className='flex mt-3 gap-3'>
                                <button className='bg-cyan-600 hover:bg-blue-500 text-white 2xl:px-2 2xl:py-1 font-bold px-8 rounded-lg py-3'> <RouterLink to='/login'>Login</RouterLink></button>
                                <button className='bg-[#269481] hover:bg-[#35ae99] text-white 2xl:px-2 2xl:py-1 font-bold px-8 rounded-lg py-3'> <RouterLink to='/signup'>Register</RouterLink></button>
                            </div>
                
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}
