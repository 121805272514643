import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom'; 

export default function HomeNav() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    // Function to toggle sidebar
    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div>
            <div className='nav-bar'>
                <nav id='home' className={`navbar  text-white  w-full flex justify-between items-center   2xl:px-[20px] px-[100px] transition-all duration-500 ${isSidebarOpen ? 'h-[230px]' : 'h-[100px]'}`}>
                    <div className='logo flex justify-between items-center gap-4'>
                        <i className='bx bx-plus-medical text-4xl text-[#266c94] border-l-8 border-b-4 border-black rounded-full p-2'></i>
                        <h1 className='text-2xl text-gray-50 XM:hidden  font-bold'>HM Hospital</h1>
                    </div>

                    {/* Desktop Navigation */}
                    <div className="icons flex gap-[50px] font-semibold 2xl:ml-[130px]  ml-[200px] md:flex">
                        <span id="close" className="hidden cursor-pointer mt-[10px] text-red-600">X</span>
                        

                        

                    </div>

                    <div className='flex gap-3'>
                        <button className='regstr bg-cyan-600 hover:bg-blue-500 text-white 2xl:px-2 2xl:py-1 font-bold px-8 rounded-lg py-3'>
                            <RouterLink to='/login'>Login</RouterLink> {/* Updated link */}
                        </button>
                        <button className='regstr bg-[#269481] hover:bg-[#35ae99] text-white 2xl:px-2 2xl:py-1 font-bold px-8 rounded-lg py-3'>
                            <RouterLink to='/signup'>Register</RouterLink> {/* Updated link */}
                        </button>
                    </div>

                    {/* Menu icon for mobile */}
                    <div className="menu hidden" onClick={toggleSidebar}>
                        <i className="bx bx-menu  text-white text-4xl font-semibold cursor-pointer"></i>
                    </div>
                </nav>

                <div  className='  py-[100px] 2xl:px-[100px]  xxm:py-[40px] lg:py-[50px] xm:px-[40px]  md:px-[80px] sm:px-[50px] px-[190px] '>
                <div className=''>
                <div className=''>
                    <div className='headi max-h-screen xxxm:mt-[60px]  '>
                            <h1 className='big text-gray-50 w-[80%] XM:text[30px] font-bold text-[50px] xm:text-[20x]'>Empowering Health Choices For a Vibrant Life You <span className='text-[#5dedd5]'>Trusted</span></h1>
                            <p className='w-[70%] text-gray-50 mt-4 text-[15px]'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore blanditiis dolore in magnam asperiores, natus dolorem atque doloremque, vitae doloribus cupiditate. Non sapiente velit eius. Similique blanditiis distinctio necessitatibus assumenda </p>
                    </div>
                </div>
                <div className='mt-[80px] xm:mt-[40px] lg:flex-wrap lg:gap-3 lg:justify-center cursor-pointer xm:-ml-5 flex'>
                    <div className='w-[300px] hover:scale-105 duration-300 hover:mr-3 border XM:overflow-x-auto lg:w-full lg:rounded-lg rounded-l-lg border-white px-3 py-5'>
                        <div className='flex justify-between items-center'>
                            <h1 className='text-xl text-white font-bold'>Oru Service Providers</h1>
                            <i className="fa-solid text-2xl text-white font-bold fa-circle-arrow-right fa-beat"></i>
                            </div>
                        <div>
                        <i className="fa-solid text-4xl mt-8 text-white font-bold fa-heart-pulse"></i>
                        </div>

                    </div>
                    <div className='w-[300px] hover:mr-3 hover:ml-3 hover:scale-105 duration-300 border XM:overflow-x-auto lg:w-full lg:rounded-lg  border-white px-3 py-5'>
                        <div className='flex justify-between items-center'>
                            <h1 className='text-xl text-white font-bold'>Book An Appointment</h1>
                            <i className="fa-solid text-2xl text-white font-bold fa-circle-arrow-right fa-beat"></i>
                            </div>
                        <div className=''>
                        <i className="fa-solid text-4xl  mt-8 text-white font-bold fa-calendar-days"></i>
                        </div>

                    </div>

                    <div className='w-[300px] hover:ml-3 hover:scale-105 duration-300 border XM:overflow-x-auto lg:w-full lg:rounded-lg bg-[#35ae9a8b]  border-white rounded-r-lg px-3 py-5'>
                        <div className='flex justify-between items-center'>
                            <h1 className='text-xl text-white font-bold'>Have An Emergency</h1>
                            <i className="fa-solid text-2xl text-white font-bold fa-circle-arrow-right fa-beat"></i>
                            </div>
                        
                        <div className='flex lg:flex-wrap  items-center gap-5'>
                            <i className="fa-solid text-4xl mt-8 text-white font-bold fa-phone"></i>
                            <div className='mt-8 lg:mt-0'>
                                <h1 className='font-bold text-white'>Emergency Line</h1>
                                <h1 className='font-bold text-white'>1-800-900</h1>
                            </div>
                        </div>
                       

                    </div>
                </div>
                </div>
                </div>


            

                <div className={`fixed top-0 right-0 w-full max-h-screen bg-[#35ae99] shadow-lg transform transition-transform ${isSidebarOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                    <div className="flex flex-col p-4">
                        <span className="self-end text-xl cursor-pointer" onClick={toggleSidebar}>X</span>
                        <div className='flex flex-col gap-2'>
                            <span className="hover:text-blue-500 text-[#102039]"><RouterLink to='/'>HOME</RouterLink></span>
                            <span className="hover:text-blue-500  text-[#102039]"><RouterLink to='/newdash'>ADMIN</RouterLink></span>
                            <Link to="doctor" smooth={true} duration={500} className="cursor-pointer text-[#102039] hover:text-blue-500">DOCTORS</Link>
                            <Link to="about" smooth={true} duration={500} className="cursor-pointer hover:text-blue-500 text-[#102039]">ABOUT US</Link>
                            <div className='flex mt-3 gap-3'>
                                <button className='bg-cyan-600 hover:bg-blue-500 text-white 2xl:px-2 2xl:py-1 font-bold px-8 rounded-lg py-3'> <RouterLink to='/login'>Login</RouterLink></button>
                                <button className='bg-[#269481] hover:bg-[#35ae99] text-white 2xl:px-2 2xl:py-1 font-bold px-8 rounded-lg py-3'> <RouterLink to='/signup'>Register</RouterLink></button>
                            </div>
                
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}
