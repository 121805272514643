// YearlyIncrementChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

const YearlyIncrementChart = ({ monthlyIncrements }) => {
  const data = {
    labels: Array(12).fill(''), // 12 labels for each month, left blank
    datasets: [
      {
        data: monthlyIncrements,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderRadius: 5,
        barThickness: 10, // Customize the bar thickness
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: { enabled: false },
      legend: { display: false },
    },
    scales: {
      x: { display: false, max: Math.max(...monthlyIncrements) * 1.2 }, // Max limit for X-axis with padding
      y: { display: false },
    },
  };

  return (
    <div className="relative w-full h-24">
      <Bar data={data} options={options} />
    </div>
  );
};

export default YearlyIncrementChart;
