import React, { useState } from "react";
import DNavbar from "./DNavbar";


export default function AddPatient() {
 
  return (
    <div>
      <DNavbar />
      <div className='px-[30px] '><hr className="border-1 border-[#afe9fd] " /></div>
      <section className="py-[60px]  sm:-mt-[100px] sm:py-[80] bg-[#f4fcff]  px-[140px]   2xl:px-[10px]    ">
        <h1 className="text-4xl font-bold xl:justify-center sm:mt-[100px] 2xl:flex text-green-800">Add Patient</h1>
        <form action="">
          {/* first row */}
          <div className="flex lg:gap-4 xl:justify-center flex-wrap gap-5 justify-between items-center mt-10">
            <div className="flex flex-col w-[600px]">
              <label htmlFor="PatientName" className="font-bold text-xl ">
                Patient Name
              </label>
             
                <input
                  type="text"
                  id="PatientName"
                  placeholder="Patient Name"
                  className="mt-2  bg-gray-50 py-1 px-3 rounded border border-blue-400 lg:mb-4"
                  required
                />
             
            </div>
            <div className="flex flex-col  w-[600px]">
              <label htmlFor="Email" className="font-bold text-xl">
                Guardian Name
              </label>
              <input
                type="text"
                id="Email"
                placeholder="Enter Guardian Name"
                className="mt-2  bg-gray-50 py-1 px-3 rounded border border-blue-400"
                required
              />
            </div>
          </div>

          <div className="flex flex-wrap xl:justify-center lg:gap-4 justify-between items-center mt-10">
          
              <div className="flex flex-wrap justify-between gap-5 ">
                  <div className="flex flex-col xm:w-full ssm:w-[250px] w-[140px]">
                      <label htmlFor="gender" className="font-bold text-xl">
                        Gender
                      </label>
                      <select
                        className="mt-2 bg-gray-50   py-1 px-3 rounded border border-blue-400"
                        name=""
                        id=""
                      >
                        <option selected disabled value="">
                          Select 
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                  </div>
    
                  <div className="flex flex-col xm:w-full ssm:w-[250px] w-[170px]">
                      <label htmlFor="Phone" className="font-bold text-xl ">
                        Date of Birth
                      </label>
                      <input
                        type="text"
                        id="DOB"
                        placeholder="Enter DOB"
                        className="mt-2   bg-gray-50 py-1 px-3 rounded border border-blue-400 lg:mb-4"
                        required
                      />
                  </div>

                  <div className="flex flex-col">
                      <label htmlFor="Phone" className="font-bold text-xl ">
                       Age (yy-mm-dd)
                      </label>
                      <div className="flex  gap-1">
                          <input
                            type="text"
                            id="DOB"
                            placeholder="Year"
                            className="mt-2 w-[70px]  bg-gray-50 py-1 px-3 rounded border border-blue-400 lg:mb-4"
                            required
                          />
                          <input
                            type="text"
                            id="DOB"
                            placeholder="Month"
                            className="mt-2 w-[100px]  bg-gray-50 py-1 px-3 rounded border border-blue-400 lg:mb-4"
                            required
                          />
                          <input
                            type="text"
                            id="DOB"
                            placeholder="Day"
                            className="mt-2 w-[70px] bg-gray-50 py-1 px-3 rounded border border-blue-400 lg:mb-4"
                            required
                          />
                      </div>
                  </div>
            </div>
            <div className="flex flex-wrap xl:mt-10 lg:mt-0  justify-between gap-5 items-center">
                  <div className="flex flex-col xm:w-full ssm:w-[250px]  w-[140px]">
                      <label htmlFor="gender" className="font-bold text-xl">
                        Blood Group
                      </label>
                      <select
                        className="mt-2 bg-gray-50  py-1 px-3 rounded border border-blue-400"
                        name=""
                        id=""
                      >
                        <option selected disabled value="">
                          Select 
                        </option>
                        <option value="Male">O+</option>
                        <option value="Female">B+</option>
                        <option value="Other">AB+</option>
                      </select>
                  </div>
    
                 
                  <div className="flex flex-col xm:w-full ssm:w-[250px] w-[140px]">
                      <label htmlFor="status" className="font-bold text-xl ">
                        Marital Status
                      </label>
                      <select
                        className="mt-2 bg-gray-50   py-1 px-3 rounded border border-blue-400"
                        name=""
                        id="status"
                      >
                        <option selected disabled value="">
                          Select 
                        </option>
                        <option value="Male">Single</option>
                        <option value="Female">Married</option>
                        <option value="Other">Divirced</option>
                      </select>
                  </div>
                  <div className="flex flex-col  w-[270px]">
                      <label htmlFor="image" className="font-bold text-xl ">
                       Patient Photo
                      </label>
                          <input
                            type="file"
                            id="image"
                            placeholder="Drop a file here"
                            className="mt-2  bg-gray-50 py-1 px-3 rounded border border-blue-400 lg:mb-4"
                            required
                          />
                     
                  </div>
            </div>
          </div>


         
          <div className="flex flex-wrap xl:justify-center gap-4  justify-between items-center mt-10">
            <div className="flex flex-col w-[600px]">
              <label htmlFor="Phone" className="font-bold text-xl ">
                Phone
              </label>
              <input
                type="text"
                id="Phone"
                placeholder="Enter Phone Number"
                className="mt-2   bg-gray-50 py-1 px-3 rounded border border-blue-400 lg:mb-4"
                required
              />
            </div>
            <div className="flex flex-col w-[600px]">
              <label htmlFor="Email" className="font-bold text-xl">
                Email
              </label>
              <input
                type="text"
                id="Email"
                placeholder="Enter Email"
                className="mt-2    bg-gray-50 py-1 px-3 rounded border border-blue-400 lg:mb-4"
                required
              />
            </div>
            
          </div>
          <div className="flex xl:m-auto flex-col mt-10 w-full ssm:w-auto  xl:w-[600px]">
              <label htmlFor="Address" className="font-bold text-xl">
                Address
              </label>
              <input
                type="text"
                id="Address"
                placeholder="Enter Address"
                className="mt-2   bg-gray-50 py-1 px-3 rounded border border-blue-400 lg:mb-4"
                required
              />
            </div>

          <div className="flex xl:justify-center lg:gap-4 flex-wrap justify-between items-center mt-10">
            <div className="flex flex-col  w-[600px]">
              <label htmlFor="Remarks" className="font-bold text-xl ">
                Remarks
              </label>
              <input
                type="text"
                id="remarks"
                className="mt-2   bg-gray-50 py-1 px-3 rounded border border-blue-400 lg:mb-4"
                required
              />
            </div>
            <div className="flex flex-col 2xl:mt-5 lg:mt-0 w-[600px]">
              <label htmlFor="Email" className="font-bold text-xl">
                Any Known Allergies
              </label>
              <input
                type="text"
                id="Email"
                className="mt-2   bg-gray-50 py-1 px-3 rounded border border-blue-400"
                required
              />
            </div>
          </div>
          <div className="flex flex-wrap xl:justify-center justify-between gap-8 items-center mt-10">
           
            <div className="flex flex-col w-[600px]">
              <label htmlFor="TPA ID" className="font-bold text-xl">
                TPA ID
              </label>
              <input
                type="text"
                id="TPA ID"
                className="mt-2 bg-gray-50 py-1 px-3 rounded border border-blue-400 lg:mb-4"
                required
              />
            </div>
            <div className="flex flex-col w-[600px]">
              <label htmlFor="TPA Validity" className="font-bold text-xl">
                TPA Validity
              </label>
              <input
                type="text"
                id="TPA Validity"
                className="mt-2  bg-gray-50 py-1 px-3 rounded border border-blue-400 lg:mb-4"
                required
              />
            </div>
          </div>
          <div className="flex xl:m-auto xm:mt-10 ssm:w-auto flex-col w-full lg:-mt-4 xl:w-[600px]">
              <label htmlFor="Phone" className="font-bold text-xl ">
                TPA
              </label>
              <select
                className="mt-2 bg-gray-50 py-1 px-3 rounded border border-blue-400"
                name=""
                id="status"
                >
                <option selected disabled value="">
                     Select 
                </option>
                <option value=""></option>
                </select>
            </div>
          
          <div className="2xl:flex xl:justify-center">
            <button
              type="submit"
              className="w-full xl:w-[600px]  mt-8 p-2 font-bold rounded-lg bg-green-900  hover:bg-green-700 text-white "
            >
                Add Patient
            </button>
          </div>
        </form>
      </section>
      
    </div>
  );
}
