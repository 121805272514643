import React from 'react';
import HomeNav from '../Home/HomeNav';
import Footer from '../Home/Footer';
import { Link as RouterLink } from 'react-router-dom'; 
import AuthNav from './AuthNav';
import SignupNav from './SignUpNav';

export default function Signup() {
 

  return (
    <div className='bg-[#f9fff4]'>
      <div className='section-1 md:bg-center'><SignupNav/></div>
      
      
    </div>
  );
}
