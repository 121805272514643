import React, { useState } from 'react';
import image from '../images/admin.png';
import SidebarN from './SidebarN';
import p1 from '../images/p1jpeg.jpeg'
import hugo from '../images/hugojpeg.jpeg'
import adam from '../images/adamjpeg.jpeg'
import mich from '../images/mich.jpeg'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Link as RouterLink } from 'react-router-dom'; 
import { Link } from 'react-scroll';
import EarningsAreaChart from './EarningsAreaChart';
import d1 from '../images/Doctor_1-removebg-preview.png'
import d13 from '../images/doc13.png'
import d12 from '../images/doc12.png'
import alex from '../images/alexjpeg.jpeg'
import david from '../images/davidjpeg.jpeg'



export default function NewDash() {
    const earnings = [3000, 4000, 5000, 7000, 6000, 8000, 9000, 12000, 11000, 15000, 14000, 16000];
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isUserSidebar, setIsuserSidebar] = useState(false);
  const [date, setDate] = useState(new Date());
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); 


  // Function to toggle sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };


  const toggleUserSidebar = () => {
    setIsuserSidebar(!isUserSidebar);
  };

  return (
    <div className="bg-[#f4fcff] min-h-screen flex">
      {/* Sidebar */}
      <div className="2xl:hidden">
        <SidebarN />
      </div>

      <div className="w-full">
        

        {/* Main Content Area */}
        <div className="pt-12  px-[10px] 2xl:ml-0 ml-[150px] xl:pt-0  xl:pl-0 transition-all duration-300 ">
          {/* Add main content here */}
          <div className='px-[80px] 2xl:py-10  sm:px-3 xxm:px-0  flex justify-between items-center'>
          <i
            onClick={toggleSidebar}
            className="bx bx-menu hidden 2xl:block text-2xl text-cyan-500 font-bold cursor-pointer"
          ></i>
              <h2 className="text-2xl xl:hidden font-semibold">Good Day <span className='font-bold'>Admin</span></h2>
              <div className="flex  items-center sm:gap-4 gap-6">
          <div className='flex items-center cursor-pointer  gap-5'>
            <div className='relative'>
              <div className='border-2 border-[#afe9fd]  hover:bg-gray-100 rounded-full px-3 py-2'>
                <i className="fa-regular fa-bell text-xl font-bold text-green-400"></i>
              </div>
              <span className='absolute -top-2 -right-1 bg-red-500 text-white rounded-full text-xs font-bold px-2 py-1'>2</span>
            </div>
            <div className='relative'>
              <div className='border-2 border-[#afe9fd] hover:bg-gray-100 rounded-full px-3 py-2'>
                <i className="fa-solid fa-gift text-xl font-bold text-green-400"></i>
              </div>
              <span className='absolute -top-2 -right-1 bg-blue-500 text-white rounded-full text-xs font-bold px-2 py-1'>5</span>
            </div>
          </div>
           <div className='flex rounded-full xl:hidden border-2 border-[#afe9fd]  bg-gray-100 items-center '>
              <img
                src={image}
                onClick={toggleUserSidebar}
                alt="Admin"
                className="w-[50px] h-[50px]  rounded-full cursor-pointer bg-gray-500"
              />
           </div>
           <div className='xl:flex hidden  rounded-full bg-gray-100 items-center '>
              <img
                src={image}
                onClick={toggleUserSidebar}
                alt="Admin"
                className="w-[40px] h-[40px]  rounded-full cursor-pointer bg-gray-500"
              />
              <h1 className="xxxm:hidden font-bold text-green-400 px-2">Hello, Admin</h1>
           </div>
          </div>
          
              
          </div>
          <div className='px-[80px] 2xl:-mt-[30px] 2xl:px-0 mt-3'><hr className=' border-1 border-[#afe9fd]'/></div>

          <div className='px-[30px]  sm:-ml-[10px] 2xl:px-5  ml-[60px] 2xl:flex-wrap flex gap-10 mt-4'>
            <div className=' w-[330px]  sm:w-[390px] xxxm:mb-[50px] XM:mb-[150px] sm:m-auto 2xl:m-auto 2xl:mt-5 2xl:ml-0   h-[400px]'>
                <div className='flex justify-between'>
                    <h1 className='text-xl text-gray-700 font-bold'>Patient List</h1>
                    <select className='bg-[#f4fcff]' name="" id="">
                        <option value="">Today</option>
                    </select>
                </div>
                {/* patient details */}
                <div className='2xl:border 2xl:rounded-lg  2xl:border-[#afe9fd] 2xl:px-1 2xl:mt-3'>
                    <div className='border  XM border-[#afe9fd] mt-5 px-4 py-3  xxxm:flex-wrap xxxm:gap-3 rounded-lg flex justify-between items-center '>
                        <div className='flex  items-center gap-3'>
                            <img src={p1} className='w-[45px] h-[45px] border-2 border-green-500 rounded-full' alt="" />
                            <div>
                            <h1 className='font-bold '>Maya Takur</h1>
                            <p className='text-gray-400'>Report</p>
                        </div>
                        </div>
                        
                        <div className='bg-gray-200 rounded-full px-3 py-1'>
                            <p className='text-green-500 font-bold'>on going</p>
                        </div>
                    </div>

                    <div className=' mt-3 px-4 py-3 rounded-lg flex  xxxm:flex-wrap xxxm:gap-3 justify-between items-center '>
                        <div className='flex items-center gap-3'>
                            <img src={hugo} className='w-[45px] h-[45px] border-2 border-purple-500 rounded-full' alt="" />
                            <div>
                            <h1 className='font-bold '>Hugo Loris</h1>
                            <p className='text-gray-400'>Medical checkup</p>
                        </div>
                        </div>
                        
                        <div className='bg-gray-200 rounded-full px-3 py-1'>
                            <p className='text-purple-500 font-bold'>9:00 AM</p>
                        </div>
                    </div>

                    <div className=' mt-3 px-4 py-3 rounded-lg flex  xxxm:flex-wrap xxxm:gap-3 justify-between items-center '>
                        <div className='flex items-center gap-3'>
                            <img src={adam} className='w-[45px] h-[45px] border-2 border-red-700 rounded-full' alt="" />
                            <div>
                            <h1 className='font-bold '>mouris Joe</h1>
                            <p className='text-gray-400'>Report</p>
                        </div>
                        </div>
                        
                        <div className='bg-gray-200 rounded-full px-3 py-1'>
                            <p className='text-red-700 font-bold'>9:30 AM</p>
                        </div>
                    </div>
                    <div className=' mt-3 px-4 py-3 rounded-lg flex  xxxm:flex-wrap xxxm:gap-3 justify-between items-center '>
                        <div className='flex items-center gap-3'>
                            <img src={mich} className='w-[45px] h-[45px] border-2 border-red-500 rounded-full' alt="" />
                            <div>
                            <h1 className='font-bold '>Michel Oven</h1>
                            <p className='text-gray-400'>Medical Checkup</p>
                        </div>
                        </div>
                        
                        <div className='bg-gray-200 rounded-full px-3 py-1'>
                            <p className='text-red-500 font-bold'>10:45 AM</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className='sm:m-auto xxxm:overflow-x-auto xxm:mt-[50px] XM:mt-[200px]  xxxm:mt-[150px]'>
                <h1 className='font-bold text-xl text-gray-700'>Consultation</h1>
                <div className='mt-5 border  xxxm:overflow-x-auto shadow-lg border-green-500 px-4 py-3 rounded-lg'>
                <div className='flex items-center  gap-3'>
                        <img src={p1} className='w-[55px] h-[55px] border-2 border-green-500 rounded-full' alt="" />
                        <div>
                        <h1 className='font-bold text-xl'>Maya Takur</h1>
                        <p className='text-gray-400'>Female - 36 years (07.02.1987)</p>
                        </div>
                </div>

                <div className='mt-10 flex gap-16 flex-wrap sm:gap-3 items-center justify-between'>
                    <h1 className='font-bold'>Symptoms</h1>
                    <div className='flex gap-10'>
                        <div>
                            <div className='bg-[#24a4c9] px-3 py-1 rounded-lg'>
                            <i className="fa-solid text-2xl  font bold text-white fa-temperature-full"></i>
                            </div>
                            <h1 className='font-semibold text-gray-700'>Fever</h1>
                        </div>
                        <div>
                            <div className='bg-[#24a4c9] w-[40px] m-auto px-2 py-1 rounded-lg'>
                            <i className="fa-solid text-2xl  font bold text-white fa-heart-pulse"></i>
                            </div>
                            <div><h1 className='font-semibold text-gray-700'>Chest pain</h1></div>
                            
                        </div>
                        <div>
                            <div className='bg-[#24a4c9] w-[40px] m-auto px-2 py-1 rounded-lg'>
                            <i className="fa-solid text-2xl  font bold text-white fa-virus"></i>
                            </div>
                            <h1 className='font-semibold text-gray-700'>Infection</h1>
                        </div>
                    </div>
                </div>

                <div className='mt-10 flex gap-16 sm:gap-6  justify-between'>
                    <h1 className='font-bold'>Last Checked</h1>
                    <div className='flex gap-5'>
                        <div>
                            <p className='text-gray-700'>Dr.Catherine on Charite Hospital on 31 oct </p>
                            <p className=' underline text-blue-400 cursor-pointer'>prescription</p>
                        </div>
                    </div>
                </div>

                <div className='mt-10 flex sm:gap-6 gap-24'>
                    <h1 className='font-bold'>Diagnosis</h1>
                    <div className='flex gap-5'>
                        <div>
                            <p className='text-gray-700'>Virus Disease, ICD-10 code B34,9 </p>
                            <p className=' underline text-blue-400 cursor-pointer'>View more</p>
                        </div>
                    </div>
                </div>

                </div>
            </div>

            <div className='xm:overflow-x-auto 2xl:m-auto lg:m-0'>
              <h1 className='font-bold  text-xl text-gray-700'>Calendar</h1>
              <div className='mt-5 border  border-gray-300 rounded-lg p-4 bg-[#f4fcff] shadow'>
                <Calendar
                  onChange={setDate}
                  value={date}
                  className="rounded-lg bg-[#f4fcff] "
                  showNeighboringMonth={false}
                />
                <p className='mt-4 text-gray-600 text-sm'>Selected Date: <span className="font-semibold text-gray-800">{date.toDateString()}</span></p>
                <div className='mt-2'>
                <div className='flex justify-between'>
                    <h1 className='font-semibold'>Upcoming</h1>
                    <p className=' underline text-blue-400 cursor-pointer'>View more</p>
                </div>
              </div>
              </div>
              
            </div>

          </div>
        </div>

        <div className='ml-[270px] 2xl:ml-0 2xl:px-10 py-10'>

          <div className=" ">
              <button className="bg-gray-600 rounded-lg  hover:bg-gray-500 px-2 py-1 mb-2 text-white font-bold">
                <span>
                  <RouterLink to="/addP">Add Patients</RouterLink>
                </span>
                <i className="fa-solid fa-circle-plus ml-1"></i>
              </button>
            </div>    
        <div className="flex  xxxm:overflow-x-auto  sm:justify-center   flex-wrap gap-10  items-center">
          {/* Patient */}   
          
            <div className="w-[250px] sm:w-full  py-[10px] bg-[#f97276] shadow-lg items-center cursor-pointer hover:bg-red-300 max-h-screen rounded-lg  ">
              <div className="flex  xxxm:flex-wrap-reverse px-[20px] justify-between items-center">
                <div className='flex flex-col gap-2'>
                  <p className="text-xl text-white">Total Patients</p>
                  <div className='flex gap-4 '>
                    <h1 className='text-white font-bold text-2xl'>5</h1>
                    <div className='flex flex-col'>
                      <i className="fa-duotone font-bold mt-2 text-white fa-solid fa-chart-line"></i>
                      <h1 className='text-white'>+4%</h1>
                      </div>
                  </div>
                  
                  </div>
                <i className="fa-regular  border-2 border-gray-200 fa-user text-2xl px-3 py-2 rounded-full text-white font-bold"></i>
              </div>
            </div>

          {/* Doctors */}
          <div className="w-[250px] sm:w-full py-[10px] bg-[#58b46d] shadow-lg items-center cursor-pointer hover:bg-green-300 max-h-screen rounded-lg  ">
              <div className="flex  xxxm:flex-wrap-reverse px-[20px] justify-between items-center">
                <div className='flex flex-col gap-2'>
                  <p className="text-xl text-white">Doctor</p>
                  <div className='flex gap-4 '>
                    <h1 className='text-white font-bold text-2xl'>4</h1>
                    <div className='flex flex-col'>
                      <i className="fa-duotone font-bold mt-2 text-white fa-solid fa-chart-line"></i>
                      <h1 className='text-white'>+.4%</h1>
                      </div>
                  </div>
                  
                  </div>
                <i className="fa-regular  border-2 border-gray-200 fa-user-doctor text-2xl px-3 py-2 rounded-full text-white font-bold"></i>
              </div>
            </div>

          {/* Appointments */}
          <div className="w-[250px] xxxm:w-auto sm:w-full  py-[10px] bg-[#74b4d1] shadow-lg items-center cursor-pointer hover:bg-blue-300 max-h-screen rounded-lg  ">
              <div className="flex  xxxm:flex-wrap-reverse px-[20px] justify-between items-center">
                <div className='flex flex-col gap-2'>
                  <p className="text-xl xxxm:-ml-3  text-white">Appointments</p>
                  <div className='flex gap-4 '>
                    <h1 className='text-white font-bold text-2xl'>6</h1>
                    <div className='flex flex-col'>
                      <i className="bx bx-line-chart-down font-bold mt-2 text-white fa-solid"></i>
                      <h1 className='text-white'>+.2%</h1>
                      </div>
                  </div>
                  
                  </div>
                <i className="fa-regular  border-2 border-gray-200 fa-calendar-days text-2xl px-3 py-2 rounded-full text-white font-bold"></i>
              </div>
            </div>
              {/* noti */}
            <div className="w-[250px] sm:w-full py-[10px] bg-[#c58dd7] shadow-lg  items-center cursor-pointer hover:bg-purple-300 max-h-screen rounded-lg  ">
              <div className="flex px-[20px]  xxxm:flex-wrap-reverse justify-between items-center">
                <div className='flex flex-col gap-2'>
                  <p className="text-xl text-white">Notifications</p>
                  <div className='flex gap-4 '>
                    <h1 className='text-white font-bold text-2xl'>48</h1>
                    <div className='flex flex-col'>
                      <i className="fa-duotone font-bold mt-2 text-white fa-solid fa-chart-line"></i>
                      <h1 className='text-white'>+.5%</h1>
                      </div>
                  </div>
                  
                  </div>
                <i className="fa-regular border-2 border-gray-200 fa-bell text-2xl px-3 py-2 rounded-full text-white font-bold"></i>
              </div>
            </div>
            {/* Balance and patient statis */}
            <div className='w-[300px] xl:w-[400px] xl:px-10 mt-3 rounded-lg border border-[#afe9fd] shadow-lg bg-[#f4fcff] px-3 py-4 max-h-screen'>
              <div>
                <h1 className='font-bold'>Balance</h1>
                <p className='text-cyan-500 mt-2 font-bold'>10241.98</p>
              </div>

            </div>
            <div className='w-[700px] xxxm:w-[250px] xxxm:px-2 xm:py-5 border border-[#afe9fd]  mt-3 xl:w-auto rounded-lg shadow-lg bg-[#f4fcff] px-10 py-7 max-h-screen'>
              <div className='flex  justify-between gap-3 flex-wrap'>
                <div><h1 className='font-bold'>Patient Statistic</h1></div>
                <div className='flex gap-5 xxxm:flex-wrap items-center'>
                  <div className='bg-green-500 px-2 py-1 rounded'>
                    <h1 className='text-white font-semibold'>Monthly</h1>
                  </div>
                  <div>
                    <h1 className='text-green-500 font-semibold'>Weekly</h1>
                  </div>
                  <div>
                    <h1 className='text-green-500 font-semibold'>Today</h1>
                  </div>
                </div>
                
              </div>

            </div>

        </div>
         {/* income */}
         <div className='flex flex-wrap xxxm:overflow-x-auto  xxm:-ml-[40px] xm:-ml-[12px]   xl:ml-[50px] xl:px-[20px] gap-10 mt-10 mb-16 '>

              <div className='flex hover:scale-105  shadow-lg border border-[#afe9fd] rounded duration-300 cursor-pointer'>
                <div className='bg-green-500 rounded xm:py-5 p-3 '>
                  <i className="fa-solid text-xl text-white font-bold fa-stethoscope"></i>
                  </div>
                <div className='w-[180px] md:w-auto xxxm:w-auto  xm:w-[250px]  flex gap-3 rounded  bg-[#f4fcff] px-3 py-1 max-h-screen'>
                  <div className='xm:ml-[20px]'>
                    <h1>OPD Income</h1>
                    <h1 className=' font-bold'>$10,284.24</h1>
                  </div>
                </div>
              </div>

              <div className='flex hover:scale-105 shadow-lg  border border-[#afe9fd] rounded duration-300 cursor-pointer'>
                <div className='bg-green-500 rounded md:py-5  p-3 '>
                  <i className="fa-solid text-xl text-white font-bold fa-mortar-pestle"></i>
                  </div>
                <div className='w-[180px] xm:w-[250px] xxxm:w-auto md:w-[115px] flex gap-3 rounded  bg-[#f4fcff] px-3 py-1 max-h-screen'>
                  <div className='xm:ml-[20px]'>
                    <h1>Pharmacy Income</h1>
                    <h1 className=' font-bold'>$2,089.24</h1>
                  </div>
                </div>
              </div>

              <div className='flex hover:scale-105 shadow-lg border border-[#afe9fd] rounded duration-300 cursor-pointer'>
                <div className='bg-green-500 xm:py-5  rounded p-3 '>
                  <i className="fa-solid text-xl text-white font-bold fa-bed-pulse"></i>
                  </div>
                <div className='w-[180px] xm:w-[250px] xxxm:w-auto md:w-auto flex gap-3 rounded  bg-[#f4fcff] px-3 py-1 max-h-screen'>
                  <div className='xm:ml-[20px]'>
                    <h1>IPD Income</h1>
                    <h1 className=' font-bold'>$4,200.00</h1>
                  </div>
                </div>
              </div>

             

              <div className='flex hover:scale-105 shadow-lg  border border-[#afe9fd] rounded duration-300 cursor-pointer'>
                <div className='bg-green-500 md:py-5 rounded p-3 '>
                  <i className="fa-solid text-xl text-white font-bold fa-flask"></i>
                  </div>
                <div className='w-[180px] xm:w-[250px] xxxm:w-auto md:w-[115px] flex gap-3 rounded  bg-[#f4fcff] px-3 py-1 max-h-screen'>
                  <div className='xm:ml-[20px]'>
                    <h1>Pathology Income</h1>
                    <h1 className=' font-bold'>$1,971.24</h1>
                  </div>
                </div>
              </div>

              <div className='flex hover:scale-105 shadow-lg border border-[#afe9fd] rounded duration-300 cursor-pointer'>
                <div className='bg-green-500 md:py-5 rounded p-3 '>
                  <i className="fa-solid text-xl text-white font-bold fa-microscope"></i>
                  </div>
                <div className='w-[180px] xm:w-[250px] xxxm:w-auto md:w-[115px] flex gap-3 rounded  bg-[#f4fcff] px-3 py-1 max-h-screen'>
                  <div className='xm:ml-[20px]'>
                    <h1>Radilogy Income</h1>
                    <h1 className=' font-bold'>$2,284.30</h1>
                  </div>
                </div>
              </div>

              <div className='flex hover:scale-105  shadow-lg border border-[#afe9fd] rounded duration-300 cursor-pointer'>
                <div className='bg-green-500 md:py-5 rounded p-3 '>
                  <i className="fa-solid text-xl text-white font-bold fa-droplet"></i>
                  </div>
                <div className='w-[180px] xm:w-[250px] xxxm:w-auto md:w-[115px] flex gap-3 rounded bg-[#f4fcff] px-3 py-1 max-h-screen'>
                  <div className='xm:ml-[20px]'>
                    <h1>Blood Bank Income</h1>
                    <h1 className=' font-bold'>$1,640.48</h1>
                  </div>
                </div>
              </div>

              <div className='flex hover:scale-105 shadow-lg border border-[#afe9fd] rounded duration-300 cursor-pointer'>
                <div className='bg-green-500 md:py-5 rounded p-3 '>
                  <i className="fa-solid text-xl text-white font-bold fa-truck-medical"></i>
                  </div>
                <div className='w-[180px] xm:w-[250px] xxxm:w-auto md:w-[115px] flex gap-3 rounded  bg-[#f4fcff] px-3 py-1 max-h-screen'>
                  <div className='xm:ml-[20px]'>
                    <h1>Ambulance Income</h1>
                    <h1 className=' font-bold'>$1,284.64</h1>
                  </div>
                </div>
              </div>

              <div className='flex hover:scale-105 border shadow-lg border-[#afe9fd] rounded duration-300 cursor-pointer'>
                <div className='bg-red-400 md:py-5 rounded p-3 '>
                  <i className="fa-solid text-xl text-white font-bold fa-sack-dollar"></i>
                  </div>
                <div className='w-[180px] xm:w-[250px] xxxm:w-auto md:w-auto flex gap-3 rounded   bg-[#f4fcff] px-3 py-1 max-h-screen'>
                  <div className='xm:ml-[20px]'>
                    <h1>Expenses</h1>
                    <h1 className=' font-bold'>$1,350.24</h1>
                  </div>
                </div>
              </div>

              

          
          </div>

          <div className='lg:-ml-[40px]  xxxm:overflowx-auto overflow-y-hidden xl:ml-[0px] mb-16 px-3'>
          <EarningsAreaChart  earningsData={earnings} />
          </div>


        <div className="flex py-[20px] xxxm:overflow-x-auto 2xl:py-[200px] 2xl:-mt-[150px] xl:ml-[50px] -mt-[40px] -ml-12  lg:-ml-[100px] xm:-mt-[250px]  gap-10 flex-wrap xm:py-[200px] justify-center">
        <div className='bg-[#f4fcff] hidden lg:block  lg:ml-[200px] md:ml-[150px] xm:ml-[90px]   sm:w-[330px] lg:w-[500px] border border-[#afe9fd] rounded-lg shadow-lg  w-[800px] p-5 '>
        <div className='flex xxxm:ml-[30px] flex-wrap flex-col '>
        <div className='flex  justify-between items-center'>
            <h1 className='font-bold text-xl'>Top Rated Doctors</h1>
            <p className='text-green-500 font-semibold cursor-pointer'>View More <i className="fa-solid fa-angles-right fa-beat"></i></p>
        </div>
        <div className='flex lg:justify-center flex-wrap mt-4 gap-3 justify-between items-center'>
        <div className='w-[200px] max-h-screen border-[#afe9fd] hover:scale-105 cursor-pointer duration-300 rounded-lg border bg-[#f4fcff]'>
        <div className='bg-gray-300 border rounded-lg '>
            <img src={d1} className=' m-auto h-[200px] w-[200px]' alt="" />
        </div>
        <div className='px-[10px] py-[10px] flex flex-col'>
            <p className='text-green-500'>#D-DO1</p>
            <h1 className='text-xl font-bold'>Dr. Richard James</h1>
            <p className='text-green-500 font-semibold'>General Physician</p>
        </div>
        </div>

        <div className='w-[200px] max-h-screen border-[#afe9fd] hover:scale-105 cursor-pointer duration-300 rounded-lg border bg-[#f4fcff]'>
        <div className='bg-gray-300 border rounded-lg '>
        <img src={d13} className=' m-auto h-[200px] w-[200px]' alt="" />
        </div>
        <div className='px-[10px] py-[10px] flex flex-col'>
        <p className='text-green-500'>#D-DO2</p>
        <h1 className='text-xl font-bold'>Dr. Jennifer Gracia</h1>
        <p className='text-green-500 font-semibold'>Neurologist</p>
        </div>
        </div>

        <div className='w-[200px] max-h-screen border-[#afe9fd] hover:scale-105 cursor-pointer duration-300 rounded-lg border bg-[#f4fcff]'>
        <div className='bg-gray-300 border rounded-lg'>
        <img src={d12} className=' m-auto h-[200px] w-[200px]' alt="" />
        </div>
        <div className='px-[10px] py-[10px] flex flex-col'>
        <p className='text-green-500'>#D-DO3</p>
        <h1 className='text-xl font-bold'>Dr. Tim white</h1>
        <p className='text-green-500 font-semibold'>Gynecologist</p>
        </div>
        </div>

        
        </div>

        </div>
        </div>

          <div className=' lg:overflow-x-auto xxxm:overflow-x-auto lg:px-0 -ml-10 px-[30px]'>
            <div className='bg-[#f4fcff] lg:hidden  lg:ml-[200px] md:ml-[150px] xm:ml-[90px]   sm:w-[330px] lg:w-[500px] border border-[#afe9fd] rounded-lg shadow-lg  w-[800px] p-5 '>

              <div className='flex flex-wrap flex-col '>
                <div className='flex justify-between items-center'>
                  <h1 className='font-bold text-xl'>Top Rated Doctors</h1>
                  <p className='text-green-500 font-semibold cursor-pointer'>View More <i className="fa-solid fa-angles-right fa-beat"></i></p>
                </div>
                <div className='flex lg:justify-center flex-wrap mt-4 gap-3 justify-between items-center'>
                <div className='w-[200px] max-h-screen border-[#afe9fd] hover:scale-105 cursor-pointer duration-300 rounded-lg border bg-[#f4fcff]'>
                <div className='bg-gray-300 border rounded-lg '>
                  <img src={d1} className=' m-auto h-[200px] w-[200px]' alt="" />
                </div>
                <div className='px-[10px] py-[10px] flex flex-col'>
                  <p className='text-green-500'>#D-DO1</p>
                  <h1 className='text-xl font-bold'>Dr. Richard James</h1>
                  <p className='text-green-500 font-semibold'>General Physician</p>
                </div>
                </div>

                <div className='w-[200px] max-h-screen border-[#afe9fd] hover:scale-105 cursor-pointer duration-300 rounded-lg border bg-[#f4fcff]'>
              <div className='bg-gray-300 border rounded-lg '>
                <img src={d13} className=' m-auto h-[200px] w-[200px]' alt="" />
              </div>
              <div className='px-[10px] py-[10px] flex flex-col'>
                <p className='text-green-500'>#D-DO2</p>
                <h1 className='text-xl font-bold'>Dr. Jennifer Gracia</h1>
                <p className='text-green-500 font-semibold'>Neurologist</p>
              </div>
                </div>

                <div className='w-[200px] max-h-screen border-[#afe9fd] hover:scale-105 cursor-pointer duration-300 rounded-lg border bg-[#f4fcff]'>
              <div className='bg-gray-300 border rounded-lg'>
                <img src={d12} className=' m-auto h-[200px] w-[200px]' alt="" />
              </div>
              <div className='px-[10px] py-[10px] flex flex-col'>
                <p className='text-green-500'>#D-DO3</p>
                <h1 className='text-xl font-bold'>Dr. Tim white</h1>
                <p className='text-green-500 font-semibold'>Gynecologist</p>
              </div>
                </div>

                
              </div>

              </div>

              

            </div>
            
            <div className='bg-[#f4fcff] border lg:ml-[30px] border-[#afe9fd] rounded-lg mt-5 shadow-lg xl:px-[80px]  w-[800px] p-5 '>
          <div className='flex justify-between items-center'>
            <h1 className='font-bold'>Recent Transaction</h1>
            <div className='flex gap-3'>
              <p className='font-semibold'>Today</p>
              <p className='bg-green-500 px-2 rounded-lg text-white'>27008</p>
            </div>
            </div>
            <table className=' mt-3  rounded-lg  w-full '>
            <thead className='bg-gray-100 rounded-lg'>
              <tr>
              <th className='p-3 text-sm font-bold tracking-wide'>#</th>
                <th className='p-3 text-sm font-bold tracking-wide'>Patient</th>
                <th className='p-3 text-sm font-bold tracking-wide'>Date</th>
                <th className='p-3 text-sm font-bold tracking-wide'>Status</th>
                <th className='p-3 text-sm font-bold tracking-wide'>Amount</th>
                <th className='p-3 text-sm font-bold tracking-wide'>Method</th>
              </tr>
            </thead>
            <tbody>
                <tr>
                   <td className='p-3 text-center font-bold'>
                    1
                  </td>
                  <td className='p-3  '>
                    <div className='flex justify-center gap-5 items-center'>
                      <img src={hugo} className='w-[45px] h-[45px] rounded-full' alt="" />
                      <div className=''>
                        <h1 className='font-bold'>Hugo Loris</h1>
                        <p className='text-gray-400'>1234567890</p>
                      </div>
                    
                    </div>
                  </td>
                  <td className='p-3 text-center font-semibold'>
                    Oct 30,2024
                  </td>
                  <td className='p-3 text-center '>
                  <p className='text-green-500 rounded-full px-1 font-semibold bg-gray-100'>Paid</p>
                  </td>
                  <td className='p-3 text-center font-semibold'>
                    1000
                  </td>
                  <td className='p-3 text-center font-semibold'>
                    Cash
                  </td>
                </tr>

                <tr>
                   <td className='p-3 text-center font-bold'>
                    2
                  </td>
                  <td className='p-3  '>
                    <div className='flex justify-center gap-5 items-center'>
                      <img src={adam} className='w-[45px] h-[45px] rounded-full' alt="" />
                      <div className=''>
                        <h1 className='font-bold'>Mouris joe</h1>
                        <p className='text-gray-400'>1234567890</p>
                      </div>
                    
                    </div>
                  </td>
                  <td className='p-3 text-center font-semibold'>
                    Oct 29,2024
                  </td>
                  <td className='p-3 text-center '>
                  <p className='text-green-500 rounded-full px-1 font-semibold bg-gray-100'>Paid</p>
                  </td>
                  <td className='p-3 text-center font-semibold'>
                    2300
                  </td>
                  <td className='p-3 text-center font-semibold'>
                    Debit Card
                  </td>
                </tr>

                <tr>
                   <td className='p-3 text-center font-bold'>
                    3
                  </td>
                  <td className='p-3  '>
                    <div className='flex justify-center gap-5 items-center'>
                      <img src={mich} className='w-[45px] h-[45px] rounded-full' alt="" />
                      <div className=''>
                        <h1 className='font-bold'>Michel oven</h1>
                        <p className='text-gray-400'>1234567890</p>
                      </div>
                    
                    </div>
                  </td>
                  <td className='p-3 text-center font-semibold'>
                    Jan 29,2025
                  </td>
                  <td className='p-3 text-center '>
                  <p className='text-yellow-500 rounded-full px-1 font-semibold bg-gray-100'>pending</p>
                  </td>
                  <td className='p-3 text-center font-semibold'>
                    1800
                  </td>
                  <td className='p-3 text-center font-semibold'>
                    Cash
                  </td>
                </tr>

                <tr>
                   <td className='p-3 text-center font-bold'>
                    4
                  </td>
                  <td className='p-3  '>
                    <div className='flex justify-center gap-5 items-center'>
                      <img src={p1} className='w-[45px] h-[45px] rounded-full' alt="" />
                      <div className=''>
                        <h1 className='font-bold'>maya</h1>
                        <p className='text-gray-400'>1234567890</p>
                      </div>
                    
                    </div>
                  </td>
                  <td className='p-3 text-center font-semibold'>
                    Jan 39,2025
                  </td>
                  <td className='p-3 text-center '>
                  <p className='text-red-500 rounded-full px-1 font-semibold bg-gray-100'>cancel</p>
                  </td>
                  <td className='p-3 text-center font-semibold'>
                    1800
                  </td>
                  <td className='p-3 text-center font-semibold'>
                    Cash
                  </td>
                </tr>

            </tbody>
          </table>

            </div>

          </div>
          

          <div className='bg-[#f4fcff] xxxm:w-auto shadow-lg w-[320px] xxxm:ml-[100px] XM:ml-[200px] xxxm:h-[440px] border -ml-10 border-[#afe9fd]  lg:ml-[90px] h-[400px] mb-[200px] xxxm:mb-[300px]   rounded-lg p-5'>
              <div className='flex flex-col'>
                <h1 className='font-bold text-xl'>Recent Patients</h1>
  
                <div className='flex justify-between py-[20px] items-center'>
                  <div className='flex gap-5 items-center'>
                    <img src={p1} alt="image" className='w-[40px] h-[40px] rounded-lg' />
                    <div className=''>
                    <h1 className='font-bold'>Amira Smith</h1>
                    <p className='text-gray-400'>9895123456</p>
                  </div>
                    </div>
                  <div>
                    <p className='text-gray-400 font-semibold'>2:00 pm</p>
                  </div>
                </div>
  
                <hr className='w-full border-1 border-gray-300' />
  
                <div className='flex justify-between py-[20px] items-center'>
                  <div className='flex gap-5 items-center'>
                    <img src={adam} alt="image" className='w-[40px] h-[40px] rounded-lg' />
                    <div className=''>
                    <h1 className='font-bold'>Adam John</h1>
                    <p className='text-gray-400'>9895123456</p>
                  </div>
                    </div>
                  <div>
                    <p className='text-gray-400 font-semibold'>2:15 pm</p>
                  </div>
                </div>
  
                <hr className='w-full border-1 border-gray-300' />
  
                <div className='flex justify-between py-[20px] items-center'>
                  <div className='flex gap-5 items-center'>
                    <img src={alex} alt="image" className='w-[40px] h-[40px] rounded-lg' />
                    <div className=''>
                    <h1 className='font-bold'>Alex Morgan</h1>
                    <p className='text-gray-400'>9895123456</p>
                  </div>
                    </div>
                  <div>
                    <p className='text-gray-400 font-semibold'>2:30 pm</p>
                  </div>
                </div>
  
                <hr className='w-full border-1 border-gray-300' />
  
                <div className='flex justify-between py-[20px] items-center'>
                  <div className='flex gap-5 items-center'>
                    <img src={david} alt="image" className='w-[40px] h-[40px] rounded-lg' />
                    <div className=''>
                    <h1 className='font-bold'>David </h1>
                    <p className='text-gray-400'>9895123456</p>
                  </div>
                    </div>
                  <div>
                    <p className='text-gray-400 font-semibold'>2:45 pm</p>
                  </div>
                </div>
              </div>

              
                <div className='bg-[#f4fcff] xxxm:overflow-x-auto xxxm:overflow-y-hidden border xxxm:w-[250px]   border-[#afe9fd] shadow-lg w-[320px] mt-4 -ml-[20px] xxxm:mt-[50px] rounded-lg p-5'>
                <h1 className='font-bold'>Today Appointments</h1>
                <div className='flex items-center justify-between mt-4'>
                  <div>
                    <p className='text-gray-400'>2 hrs Later</p>
                  </div>
                  <div className='w-[10px] -ml-2  bg-orange-400 rounded-full p-2'></div>
                  <div>
                    <div className='flex flex-col '>
                      <h1 className='font-bold'>Mohan Kumar</h1>
                      <p className='text-gray-400'>10:00 AM - 12:00 PM</p>
                    </div>
                  </div>
    
                </div>
    
                <div className='flex items-center justify-between mt-4'>
                  <div>
                    <p className='text-gray-400'>1 hrs ago</p>
                  </div>
                  <div className='w-[10px] p-2 border-gray-400 bg-red-500 rounded-full'>
                  </div>
                  <div>
                    <div className='flex flex-col '>
                      <h1 className='font-bold'>Maya</h1>
                      <p className='text-gray-400'>10:00 AM - 12:00 PM</p>
                    </div>
                  </div>
    
                </div>
    
                <div className='flex items-center justify-between mt-4'>
                  <div>
                    <p className='text-gray-400'>2 hrs ago</p>
                  </div>
                  <div className='w-[10px] p-2  bg-green-500 rounded-full'>
                  </div>
                  <div>
                    <div className='flex flex-col '>
                      <h1 className='font-bold'>Jithesh</h1>
                      <p className='text-gray-400'>10:00 AM - 12:00 PM</p>
                    </div>
                  </div>
    
                </div>
    
                <div className='flex items-center justify-between mt-4'>
                  <div>
                    <p className='text-gray-400'>3 hrs ago</p>
                  </div>
                  <div className='w-[10px] p-2  bg-green-500 rounded-full'>
                  </div>
                  <div>
                    <div className='flex flex-col '>
                      <h1 className='font-bold'>David</h1>
                      <p className='text-gray-400'>10:00 AM - 12:00 PM</p>
                    </div>
                  </div>
                </div>
              

            </div>
            </div>

          

        </div>
        

      </div>


      </div>
      {/* Sidebar */}
      <div
        className={`bg-[#afe9fd]  w-64 h-screen p-5 fixed top-0 left-0 z-20 transition-transform duration-300 ease-in-out ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="flex justify-between items-center mb-8">
        <div className='logo flex justify-between items-center gap-4'>
            <i className='bx bx-plus-medical text-2xl text-cyan-500 border-l-8 border-b-4 border-black rounded-full p-1'></i>
            <h1 className='text-2xl  text-cyan-500 font-bold'>HM Hospital</h1>
        </div>
          <i
            onClick={toggleSidebar}
            className="bx bx-x text-xl font-bold cursor-pointer"
          ></i>
        </div>
        <ul className="space-y-5">
          <li className="flex items-center hover:bg-gray-100 rounded-lg gap-2">
            <div className="px-3 py-2">
              <i className="bx bxs-dashboard text-cyan-500 text-2xl"></i>
            </div>
            {isSidebarOpen && (
              <Link  className=" text-xl font-semibold">
                <RouterLink to='/newdash'>Dashboard</RouterLink>
              </Link>
            )}
          </li>
          {/* Dropdown Item */}
          <li className="flex flex-col">
            <button
              className="flex items-center w-full text-left hover:bg-cyan-100 rounded-lg gap-2 p-2"
              onClick={toggleDropdown}
            >
              <div className="px-3 py-2">
                <i className="bx bx-user  text-cyan-500 text-2xl font-bold"></i>
              </div>
              {isSidebarOpen && (
                <>
                  <span className="text-xl font-semibold">Patients</span>
                  <i
                    className={`ml-auto text-xl font-bold bx ${
                      isDropdownOpen ? 'bx-chevron-up' : 'bx-chevron-down'
                    } transition-transform duration-300`}
                  ></i>
                </>
              )}
            </button>

            {/* Dropdown Menu */}
            {isDropdownOpen && (
              <ul className="ml-10 mt-2  space-y-4 transition-all duration-300">
                <li className="flex items-center gap-2">
                  {/* Rounded Plus Icon */}
                  <button className=' hover:bg-blue-500 px-2 py-1 mb-2 rounded-lg font-bold'>
                    <span className="mr-2">
                      <i className="fas fa-plus"></i>
                    </span>
                    <RouterLink to="/addP" className="text-lg  font-medium">
                      Add Patient
                    </RouterLink>
                  </button>
                </li>
                <li>
                  <RouterLink  className="text-lg ml-4 hover:bg-blue-500 rounded-lg px-2 py-1 font-medium">
                    Patients List
                  </RouterLink>
                </li>
              </ul>
            )}
          </li>

          <li className="flex items-center hover:bg-cyan-100 rounded-lg gap-2">
            <div className="px-3 py-2">
              <i className="fa-solid fa-user-doctor text-cyan-500 font-bold text-2xl"></i>
            </div>
            {isSidebarOpen && (
              <Link  className=" text-xl font-semibold">
                Doctors
              </Link>
            )}
          </li>

          <li className="flex items-center hover:bg-cyan-100 rounded-lg gap-2">
            <div className="px-3 py-2">
              <i className="fa-solid fa-calendar-days text-cyan-500 text-2xl"></i>
            </div>
            {isSidebarOpen && (
              <Link  className=" text-xl font-semibold">
                Appointments
              </Link>
            )}
          </li>
          <li className="flex items-center rounded-lg gap-2">
            <div className="px-3 py-2">
              <i className="fa-regular fa-credit-card text-cyan-500 text-2xl"></i>
            </div>
            {isSidebarOpen && (
              <Link  className="text-xl font-semibold">
                Payments
              </Link>
            )}
          </li>
        </ul>
        <hr className="text-gray-200 w-full border mt-10" />
        <div className="flex items-center hover:bg-red-400 cursor-pointer rounded-lg gap-2 mt-5">
          <div className="px-3 py-2">
            <i className="bx bx-log-out font-bold text-2xl  text-red-600"></i>
          </div>
          {isSidebarOpen && (
            <h1 className="text-xl font-semibold">
              <RouterLink to="/login">Logout</RouterLink>
            </h1>
          )}
        </div>
        
      </div>
    </div>
  );
}