import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

export default function Sidebar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // Function to toggle sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="relative">
      {/* Sidebar */}
      <div
        className={`bg-gray-50 text-black ${
          isSidebarOpen ? 'w-60' : 'w-20'
        } h-screen p-5 fixed left-0 z-20 transition-all duration-300`}
      >
        {/* Sidebar Header */}
        <div className="flex justify-between items-center mb-[70px]">
          {isSidebarOpen ? (
            <div className='logo flex justify-between items-center gap-4'>
            <i className='bx bx-plus-medical text-2xl text-cyan-500 border-l-8 border-b-4 border-black rounded-full p-1'></i>
            <h1 className='text-2xl  text-cyan-500 font-bold'>HM Hospital</h1>
        </div>
          ) : (
            <i className="bx bxs-hospital text-2xl "></i>
          )}
          <i
            onClick={toggleSidebar}
            className={`fa-solid ${
              isSidebarOpen ? 'fa-circle-chevron-left' : 'fa-circle-chevron-right'
            } text-2xl text-cyan-500 font-bold cursor-pointer`}
          ></i>
        </div>

        {/* Sidebar Items */}
        <ul className="space-y-5">
          <li className="flex items-center hover:bg-gray-100 rounded-lg gap-2">
            <div className="px-3 py-2">
              <i className="bx bxs-dashboard text-cyan-500 text-2xl"></i>
            </div>
            {isSidebarOpen && (
              <RouterLink to="/dashboard" className=" text-xl font-semibold">
                Dashboard
              </RouterLink>
            )}
          </li>
          <li className="flex items-center hover:bg-gray-100 rounded-lg gap-2">
            <div className="px-3 py-2">
              <i className="bx bx-user text-cyan-500 text-2xl font-bold"></i>
            </div>
            {isSidebarOpen && (
              <RouterLink to="/patients" className=" text-xl font-semibold">
                Patients
              </RouterLink>
            )}
          </li>

          <li className="flex items-center hover:bg-gray-100 rounded-lg gap-2">
            <div className="px-3 py-2">
              <i className="fa-solid fa-user-doctor text-cyan-500 font-bold text-2xl"></i>
            </div>
            {isSidebarOpen && (
              <Link to="/doctors" className=" text-xl font-semibold">
                Doctors
              </Link>
            )}
          </li>

          <li className="flex items-center hover:bg-gray-100 rounded-lg gap-2">
            <div className="px-3 py-2">
              <i className="fa-solid fa-calendar-days text-cyan-500 text-2xl"></i>
            </div>
            {isSidebarOpen && (
              <Link to="/appointments" className=" text-xl font-semibold">
                Appointments
              </Link>
            )}
          </li>
          <li className="flex items-center hover:bg-gray-100 rounded-lg gap-2">
            <div className="px-3 py-2">
              <i className="fa-regular fa-credit-card text-cyan-500 text-2xl"></i>
            </div>
            {isSidebarOpen && (
              <Link to="/payments" className="text-xl font-semibold">
                Payments
              </Link>
            )}
          </li>
        </ul>
        <hr className="text-gray-200 w-full border mt-10" />
        <div className="flex items-center hover:bg-red-400 cursor-pointer rounded-lg gap-2 mt-5">
          <div className="px-3 py-2">
            <i className="bx bx-log-out font-bold text-2xl text-red-600"></i>
          </div>
          {isSidebarOpen && (
            <h1 className="text-xl font-semibold">
              <RouterLink to="/login">Logout</RouterLink>
            </h1>
          )}
        </div>
      </div>

      {/* Menu icon to show sidebar */}
      {!isSidebarOpen && (
        <button
          onClick={toggleSidebar}
          className="bx bx-menu text-2xl text-white fixed top-5 left-5 z-10 cursor-pointer"
        ></button>
      )}
    </div>
  );
}
