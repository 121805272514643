import React from 'react'
import HomeNav from '../Home/HomeNav'
import Footer from '../Home/Footer'
import { Link } from 'react-scroll'
import { Link as RouterLink } from 'react-router-dom'; 
import AuthNav from './AuthNav';
export default function Login() {
 
  return (
    <div className='bg-[#f9fff4]'>
      <div className='section-1 md:bg-center'><AuthNav/></div>
    </div>
  )
}
